import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import AssetSidebar from "./components/AssetSidebar";
import "./sidebar.css";
import {
  N_getLeaser,
  N_getLevelIncome,
  N_getRefferalData,
  N_getRewardData,
} from "./redux/helpers/api_functions_new";
import { getCoinRate } from "./redux/helpers/helper_functions";
import { add, sub } from "./redux/helpers/Math";
import MyPNL from "./MyPNL";

export default function MyBonus() {
  const { user, isLoggedIn } = useSelector((state) => state.AuthReducer);
  const { coins } = useSelector((state) => state.coinDBReducer);
  const [action, setAction] = useState("signup");
  const [referralKey, setReferralKey] = useState(null);
  const [rewardcoin, setRewardCoin] = useState("");
  const [rewardfee, setrewardfee] = useState(0);
  const [rewarddata, setRewardData] = useState(null);
  const [rewardUserEarning, setrewardUserEarning] = useState(null);
  const [referralUserData, setReferralUserData] = useState(null);
  const [referralUserEarning, setReferralUserEarning] = useState(null);
  const [levelIncome, setLevelIncome] = useState(null);
  const [levelUserEarning, setLevelUserEarning] = useState(null);
  const [levelTodayUserEarning, setLevelUserTodayEarning] = useState(null);
  const [total_deposit, setTotalDeposit] = useState(null);
  const [total_withdraw, setTotalWithdraw] = useState(null);
  const [total, setTotal] = useState(null);
  const [available_balanace, setAvailable] = useState(null);

  useEffect(() => {
    if (isLoggedIn) {
      if (action == "referral") {
        N_getRefferalData(user?.params ? user.params.token : user.token).then(
          (d) => {
            if (d.status) {
              const key = d?.params?.referral_code;
              if (key) setReferralKey(key);
              const total_ref = d?.params?.total_referals;
              const total_earn = d?.params?.total_referal_earning;

              if (total_ref && total_ref.length > 0) {
                setReferralUserData(total_ref);
              }
              if (total_earn && total_earn.length > 0) {
                setReferralUserEarning(total_earn);
              }
            }
          }
        );
      }

      if (action == "signup") {
        N_getRewardData(user?.params ? user.params.token : user.token).then(
          (res) => {
            setRewardCoin(res.signup_coin);
            setrewardfee(res.signup_commision);
            setRewardData(res.reward);
            setrewardUserEarning(res.total_earning);
          }
        );
      }

      if (action == "level") {
        N_getLevelIncome(user?.params ? user.params.token : user.token, action).then(
          (res) => {
            setLevelIncome(res.level);
            setLevelUserEarning(res.total_earning);
            setLevelUserTodayEarning(res.today_earning);
          }
        );
      }
      if (action == "staking") {
        N_getLevelIncome(user?.params ? user.params.token : user.token, action).then(
          (res) => {
            setLevelIncome(res.staking);
            setLevelUserEarning(res.total_earning);
            setLevelUserTodayEarning(res.today_earning);
          }
        );
      }
      if (action == "validator") {
        N_getLevelIncome(user?.params ? user.params.token : user.token, action).then(
          (res) => {
            setLevelIncome(res.staking);
            setLevelUserEarning(res.total_earning);
            setLevelUserTodayEarning(res.today_earning);
          }
        );
      }

      if (action == "leaser") {
        N_getLeaser(user?.params ? user.params.token : user.token).then(
          (res) => {
            if (res.status == 200) {
              let deposit_data = 0;
              let withdraw_data = 0;
              let total_spot = 0;
              let total_future = 0;
              let total_p2p = 0;

              res?.deposit_data?.length > 0 &&
                res?.deposit_data?.map((item) => {
                  if (item?._id == "INR") {
                    deposit_data += parseFloat(item?.total);
                  } else {
                    let rate = getCoinRate(coins, item?._id);
                    deposit_data += rate * parseFloat(item?.total);
                  }
                });
              res?.withdraw_data?.length > 0 &&
                res?.withdraw_data?.map((item) => {
                  if (item?._id == "INR") {
                    withdraw_data += parseFloat(item?.total);
                  } else {
                    let rate = getCoinRate(coins, item?._id);
                    withdraw_data += rate * parseFloat(item?.total);
                  }
                });

              res?.wallet_data?.length > 0 &&
                res?.wallet_data?.map((item) => {
                  if (item?._id == "INR") {
                    total_spot += parseFloat(item?.total);
                  } else {
                    let rate = getCoinRate(coins, item?._id);
                    total_spot += rate * parseFloat(item?.total);
                  }
                });
              res?.p2pwallet_data?.length > 0 &&
                res?.p2pwallet_data?.map((item) => {
                  if (item?._id == "INR") {
                    total_p2p += parseFloat(item?.total);
                  } else {
                    let rate = getCoinRate(coins, item?._id);
                    total_p2p += rate * parseFloat(item?.total);
                  }
                });
              res?.futurewallet_data?.length > 0 &&
                res?.futurewallet_data?.map((item) => {
                  if (item?._id == "INR") {
                    total_future += parseFloat(item?.total);
                  } else {
                    let rate = getCoinRate(coins, item?._id);
                    total_future += rate * parseFloat(item?.total);
                  }
                });
              setTotalDeposit(deposit_data);
              setTotalWithdraw(withdraw_data);
              setAvailable(add(total_spot, add(total_p2p, total_future)));
              let total_available = add(
                add(total_spot, total_p2p),
                add(total_future, withdraw_data)
              );
              setTotal(sub(total_available, deposit_data));
            }
          }
        );
      }
    }
  }, [isLoggedIn, action, coins]);
  return (
    <>
      <Header />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
          <div className="container">
            <div className="marginTop">
              {action === "level" ||
              action === "staking" ||
              action === "validator" ? (
                <div className="account_page">
                  <div className="page-content-title">
                    <h3 className="">My Bounuses</h3>
                  </div>
                  <div className="">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="card box_shadow">
                          <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                              <h6 className="fw-bold mb-3">Income </h6>
                            </div>
                            <div>
                              <span className="me-1">Today Earning</span>
                              {levelTodayUserEarning &&
                              levelTodayUserEarning.length > 0 ? (
                                levelTodayUserEarning.map((item, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <span>≈ {item?.total}</span>
                                      <span> {item?._id}</span>
                                    </React.Fragment>
                                  );
                                })
                              ) : (
                                <span>≈ 0.00</span>
                              )}
                            </div>

                            <div>
                              <span className="me-1">Total Earning</span>
                              {levelUserEarning &&
                              levelUserEarning.length > 0 ? (
                                levelUserEarning.map((item, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <span>≈ {item?.total}</span>
                                      <span> {item?._id}</span>
                                    </React.Fragment>
                                  );
                                })
                              ) : (
                                <span>≈ 0.00</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="account_page">
                <div className="row">
                  <div className="activity_panel mb-4">
                    <div className="">
                      <div class="custom_pills">
                        <div class="nav nav-pills" id="nav-tab" role="tablist">
                          <div
                            class="nav-item nav-link active fs-14"
                            style={{ height: "35px" }}
                            href="#signup"
                            data-toggle="tab"
                            onClick={() => {
                              setAction("signup");
                            }}
                          >
                            Signup Reward
                          </div>

                          <div
                            class="nav-item nav-link fs-14"
                            style={{ height: "35px" }}
                            href="#referral"
                            data-toggle="tab"
                            onClick={() => {
                              setAction("referral");
                            }}
                          >
                            Referral Reward
                          </div>
                          <div
                            class="nav-item nav-link fs-14"
                            style={{ height: "35px" }}
                            href="#level"
                            data-toggle="tab"
                            onClick={() => {
                              setAction("level");
                            }}
                          >
                            Level Reward
                          </div>
                          <div
                            class="nav-item nav-link fs-14"
                            style={{ height: "35px" }}
                            href="#level"
                            data-toggle="tab"
                            onClick={() => {
                              setAction("staking");
                            }}
                          >
                            Staking Reward
                          </div>
                          <div
                            class="nav-item nav-link fs-14"
                            style={{ height: "35px" }}
                            href="#level"
                            data-toggle="tab"
                            onClick={() => {
                              setAction("validator");
                            }}
                          >
                            Validator Reward
                          </div>
                          {/* <div
                            class="nav-item nav-link fs-14"
                            style={{ height: "35px" }}
                             href="#leaser"
                            data-toggle="tab"
                            onClick={() => {
                              setAction("leaser");
                            }}
                          >
                            PNL
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-content">
                  <div className="tab-pane fade active show" id="signup">
                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                            <tr>
                              <th>Email</th>
                              <th>Symbol</th>
                              <th>Amount</th>
                              <th>Time</th>
                              <th>Level</th>
                            </tr>
                          </thead>

                          <tbody>
                            {/*============= Use this div when no data is found =============== */}
                            {rewarddata && rewarddata.length > 0 ? (
                              rewarddata.map((item) => {
                                return (
                                  <tr>
                                      <td className="border-bottom-0 text-muted">
                                      <p>{item?.level ==0? "Self":item?._from?.email}</p>
                                    </td>
                                    
                                    <td className="border-bottom-0 text-muted">
                                      <p>{item?.wallet_type}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{item?.commission}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                        {new Date(
                                          item.createdAt
                                        ).toLocaleString()}
                                      </p>
                                    </td>
                                    <td
                                      className={`border-bottom-0 text-muted$`}
                                    >
                                      <p>
                                        {item?.level ==0
                                          ? "Self"
                                          : item?.level}
                                      </p>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td
                                  colSpan={9}
                                  className="text-center border-bottom-0 text-muted"
                                >
                                  <img
                                    src="/img/no-data.png"
                                    className="no-data-found"
                                  />
                                  <p>No records</p>
                                </td>
                              </tr>
                            )}

                            {/*============= Use this div when no data is found =============== */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="referral">
                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                            <tr>
                              <th>Email</th>
                              <th>Name</th>
                              <th>Symbol</th>
                              <th>Amount</th>
                              <th>Kyc status</th>
                              <th>Level</th>
                              <th>Date</th>
                            </tr>
                          </thead>

                          <tbody>
                            {/*============= Use this div when no data is found =============== */}

                            {referralUserData && referralUserData.length > 0 ? (
                              referralUserData.map((item) => {
                                return (
                                  <tr>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{item?.email}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{item?.name ? item?.name : "N/A"}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                        {item?.wallet_type
                                          ? item?.wallet_type
                                          : 0}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                        {item?.commission
                                          ? item?.commission
                                          : 0}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                        {item?.is_kyc_verified
                                          ? "Done"
                                          : "Pending"}
                                      </p>
                                    </td>
                                    <td
                                      className={`border-bottom-0 text-muted`}
                                    >
                                      <p>
                                        {item?.level==0
                                          ? "Self"
                                          : item?.level}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                        {new Date(
                                          item?.createdAt
                                        ).toLocaleString()}
                                      </p>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td
                                  colSpan={9}
                                  className="text-center border-bottom-0 text-muted"
                                >
                                  <img
                                    src="/img/no-data.png"
                                    className="no-data-found"
                                  />
                                  <p>No records</p>
                                </td>
                              </tr>
                            )}

                            {/*============= Use this div when no data is found =============== */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="level">
                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                            <tr>
                              <th>From</th>
                              <th>Symbol</th>
                              <th>Amount</th>
                              <th>Level</th>
                            </tr>
                          </thead>

                          <tbody>
                            {/*============= Use this div when no data is found =============== */}

                            {levelIncome && levelIncome.length > 0 ? (
                              levelIncome.map((item) => {
                                return (
                                  <tr>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{item?._from?.email}</p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                        {item?.wallet_type
                                          ? item?.wallet_type
                                          : 0}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>
                                        {item?.commission
                                          ? item?.commission
                                          : 0}
                                      </p>
                                    </td>
                                    <td className="border-bottom-0 text-muted">
                                      <p>{item?.level}</p>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td
                                  colSpan={9}
                                  className="text-center border-bottom-0 text-muted"
                                >
                                  <img
                                    src="/img/no-data.png"
                                    className="no-data-found"
                                  />
                                  <p>No records</p>
                                </td>
                              </tr>
                            )}

                            {/*============= Use this div when no data is found =============== */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="leaser">
                    <MyPNL
                      totalDeposit={total_deposit}
                      totalWithdraw={total_withdraw}
                      availableBalance={available_balanace}
                      total={total}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
