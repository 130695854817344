import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import AssetSidebar from "./components/AssetSidebar";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";
import { BsInfoCircleFill } from "react-icons/bs";
import { MdOutlineInfo } from "react-icons/md";

export default function AssetsFinance() {
  return (
    <>
      <Header />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
          <div className="marginTop ">
            <div className="account_page">
              <div className="container">
                <div className="page-content-title">
                  <h3 className="">Financial Account</h3>
                </div>

                <div className="row my-4">
                  <div className="col-lg-2 mb-3">
                    <h6 className="mb-0  text-muted">
                      {" "}
                      <MdOutlineInfo className="fs-5" /> 
                    </h6>
                    <span class="desc_card">0.00</span>
                    <span class="fs-16 text-muted ms-1">BTC</span>
                    <span class="fs-16 text-lgray">
                      <span>≈ 0.00</span>
                      <span>USD</span>
                    </span>
                  </div>
                  <div className="col-lg-2 mb-3">
                    <h6 className="mb-0 text-muted"> Yesterday's Profit</h6>
                    <span class="desc_card">0.00</span>
                    <span class="fs-16 text-muted ms-1">BTC</span>
                    <span class="fs-16 text-lgray">
                      <span>≈ 0.00</span>
                      <span>USD</span>
                    </span>
                  </div>
                  <div className="col-lg-2 mb-3">
                    <h6 className="mb-0  text-muted"> Today's Profit</h6>
                    <span class="desc_card">0.00</span>
                    <span class="fs-16 text-muted ms-1">BTC</span>
                    <span class="fs-16 text-lgray">
                      <span>≈ 0.00</span>
                      <span>USD</span>
                    </span>
                  </div>
                </div>

                <div className="">
                  <div className="row">
                    <div className="activity_panel mb-4">
                      <div className="tab_width">
                        <div class="custom_pills">
                          <div
                            class="nav nav-pills"
                            id="nav-tab"
                            role="tablist"
                          >
                            <div
                              class="nav-item nav-link active fs-14"
                              style={{ height: "35px" }}
                              href="#c_holding"
                              data-toggle="tab"
                            >
                              Current Holding
                            </div>
                            <div
                              class="nav-item nav-link fs-14"
                              style={{ height: "35px" }}
                              href="#h_holding"
                              data-toggle="tab"
                            >
                              Historical Holding
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="search_input_grp">
                        <div class="input-group">
                          <span class="input-group-text">
                            <i class="fa-solid fa-magnifying-glass"></i>
                          </span>
                          <input
                            type="text"
                            placeholder="Search"
                            class="form-control text-start"
                          />
                        </div>
                      </div>

                      <div className="">
                        <a href="#" className="btn btn-pink me-2">
                          Burning Drop
                        </a>
                        <a href="#" className="btn btn-pink me-2">
                          ETH2.0
                        </a>
                        <a href="#" className="btn btn-pink">
                          NFT
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-content">
                  <div className="tab-pane fade active show" id="c_holding">
                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                            <tr>
                              <th>Product</th>
                              <th>Type</th>
                              <th>Term</th>
                              <th>Est. APR</th>
                              <th>Holding</th>
                              <th>Profit</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                colSpan={8}
                                className="text-center border-bottom-0"
                              >
                                <img
                                  src="/img/no-data.png"
                                  className="no-data-found"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="h_holding">
                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                        <table className="table global_table">
                          <thead>
                            <tr>
                              <th>Product</th>
                              <th>Type</th>
                              <th>Term</th>
                              <th>Historical Holding</th>
                              <th>Profit</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                colSpan={8}
                                className="text-center border-bottom-0"
                              >
                                <img
                                  src="/img/no-data.png"
                                  className="no-data-found"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
