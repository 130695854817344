import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { getFutureUserBalance, getFutureUserOrder, getUserBalance, getUserOrder } from "../redux/actions/coinDBAction";
import {
  N_executeFutureOrder,
  N_futureBuyOffer,
  N_futureSellOffer,
  N_get_walletfuture,
} from "../redux/helpers/api_functions_new";

import { mul, round } from "../redux/helpers/Math";
import { getRound } from "../redux/helpers/helper_functions";
import { Link } from "react-router-dom";
import { IoIosArrowDropright, IoMdArrowDropdown } from "react-icons/io";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Modal } from "react-bootstrap";
import { CALLBUYSELL } from "../redux/constant";

export default function BuyDSell(props) {
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 200,
    },
  }));

  const { isLoggedIn, user } = useSelector((state) => state.AuthReducer);
  const {
    coins,
    paired_curency_price,
    vhl_future_data,
    future_wallet,
    currency_type,
    campare_currency,
    callbybuysell
  } = useSelector((state) => state.coinDBReducer);

  // laverage
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // TP/SL
  const [showProfiltmodal, setShowProfiltmodal] = useState(false);
  const handleCloseProfiltmodal = () => setShowProfiltmodal(false);
  const handleShowProfiltmodal = () => setShowProfiltmodal(true);

  // cross
  const [showCross, setShowCross] = useState(false);
  const handleCloseCross = () => setShowCross(false);
  const handleShowCross = () => setShowCross(true);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [bloading, setbLoading] = useState(false);
  const [atPrice, setATPrice] = useState(0);
  const [atsPrice, setATSPrice] = useState(0);
  const [stopprice, setStopPrice] = useState(0);
  const [cprice, setCprice] = useState(0);
  const [spreadvalue, setSpread] = useState(4);
  const [activeTab, setActiveTab] = useState(0);
  const [amount, setAmount] = useState(0);
  const [asmount, setSAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [stotal, setSTotal] = useState(0);
  const [stopLimit, setStoplimit] = useState(0);
  const [wallet_details, setWalletDetails] = useState([]);
  const [buybalance, setbuybalance] = useState(0);
  const [sellbalance, setsellbalance] = useState(0);
  const [selectedOption, setSelectedOption] = useState("limit");
  const [future_type, setFutureType] = useState("Isolated")
  const [leverage, setLeverage] = useState(10);
  const [stop_profit, setStopProfit] = useState(0);
  const [stop_loss, setStopLoss] = useState(0);
  const [cv, setCV] = useState(leverage);

  const coin = props?.match?.params?.id
    ? props.match.params.id.split("-")
    : currency_type + "-" + campare_currency.split("-");
  const [price, setprice] = useState(0);
  let time = 0;

  useEffect(() => {
    // console.log(amount * atsPrice,stotal)
    setSTotal(round(asmount * atPrice));
  }, [atPrice, amount, total]);

  useEffect(() => {
    // console.log(asmount * atsPrice,stotal)
    setSTotal(round(asmount * atsPrice));
  }, [atsPrice, asmount, stotal]);

  function setCurrentBuySellTokenBalance() {
    wallet_details.map((item, i) => {
      if (item.symbol === coin[1].toUpperCase()) {
        setbuybalance(getRound(item.avl_balance));
      } else if (item.symbol === coin[0].toUpperCase()) {
        setsellbalance(getRound(item.avl_balance));
      }
    });
  }

  function getCurrentBuySellTokenBalance(wallet) {
    let final_data =
      wallet &&
      Object.keys(wallet).map((res, i) => {
        return {
          id: wallet[res]?.id,
          icon: wallet[res]?.icon,
          symbol: wallet[res]?.symbol.toUpperCase(),
          name: wallet[res]?.name,
          status: wallet[res]?.status,
          withdral_fee: wallet[res]?.withdrawal_fee,
          locked: getRound(wallet[res]?.locked),
          address: wallet[res]?.wallet_address,
          balance: getRound(wallet[res]?.balance),
          avl_balance: getRound(wallet[res]?.balance - wallet[res]?.locked),
        };
      });
    setWalletDetails(final_data);
    setTimeout(() => setLoading(false), 800);
  }

  useEffect(() => {
    setCurrentBuySellTokenBalance();
  }, [coin, wallet_details]);

  useEffect(() => {
    const data = Object.values(coins).find((d) => {
      if (d.symbol === coin[0].toUpperCase()) {
        return d;
      }
    });
    let spred =
      coin[1].toUpperCase() == "BTC"
        ? data?.spread_btc
        : coin[1].toUpperCase() == "USDT"
        ? data?.spread_usdt
        : coin[1].toUpperCase() == "INR"
        ? data?.spread_inr
        : data?.spread_vrx;
    // Get the data corresponding to the matching key
    setSpread(spred)
    const matchingData = vhl_future_data[props.match.params.id];
    if (matchingData) {
      // console.log('currentPrice', price)
      setprice(matchingData.raw_price.toFixed(spred));
    } else {
      setprice(
        (
          Number(data?.current_price) /
          Number(
            paired_curency_price
              ? paired_curency_price[coin[1].toUpperCase()]
                ? paired_curency_price[coin[1].toUpperCase()]
                : 1
              : 1
          )
        ).toFixed(spred)
      );
    }
  }, [coins, vhl_future_data, props?.match?.params?.id, paired_curency_price]);

  useEffect(() => {
    if (price && time == 0) {
      setATPrice(Number(price)?.toFixed(spreadvalue));
      setATSPrice(Number(price)?.toFixed(spreadvalue));
      setCprice(Number(price)?.toFixed(spreadvalue));
      time = 1;
    }
  }, [price]);

  useEffect(() => {
    if (isLoggedIn && user?.params ? user.params.token : user.token) {
      getCurrentBuySellTokenBalance(future_wallet);
    }
  }, [future_wallet]);

  // useEffect(() => {
  //   const mprice = buymarket.marketprice;
  //   const mvolume = round(buymarket.marketvolume);
  //   const totalinr = mul(mvolume, mprice);
  //   setATSPrice(mprice);
  //   setSAmount(mvolume);
  //   setSTotal(totalinr);
  //   setActiveTab(buymarket.active);
  // }, [buymarket]);

  // useEffect(() => {
  //   const mprice = sellmarket.marketprice;
  //   const mvolume = round(sellmarket.marketvolume);
  //   const totalinr = mul(mvolume, mprice);
  //   setATPrice(mprice);
  //   setAmount(mvolume);
  //   setTotal(totalinr);
  //   setActiveTab(sellmarket.active);
  // }, [sellmarket]);

  function buyCoin(atPrice, amount, c, cp) {
    setbLoading(true);
    N_futureBuyOffer(
      atPrice,
      stop_loss,
      stop_profit,
      leverage,
      amount,
      c,
      cp,
      user?.params ? user.params.token : user.token,
      cprice,
      "buy",
      selectedOption
    )
      .then((d) => {
        if (d.status == 200) {
          NotificationManager.success(d.message);
          dispatch(
            getFutureUserBalance(user?.params ? user.params.token : user.token)
          );
          N_executeFutureOrder(
              d.result.order_id,
              user?.params ? user.params.token : user.token,
              d.result.type
            ).then((res)=>{
              if(res.status==200) {
                dispatch(
                  getFutureUserBalance(user?.params ? user.params.token : user.token)
                );
                dispatch(
                  getFutureUserOrder(user?.params ? user.params.token : user.token)
                );
              }
            })
            
        } else if (d.status == 400) {
          NotificationManager.error(d.message);
        }
        setbLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function sellCoin(atPrice, amount, c, cp) {
    setLoading(true);
    N_futureSellOffer(
      atPrice,
      stop_loss,
      stop_profit,
      leverage,
      amount,
      c,
      cp,
      user?.params ? user.params.token : user.token,
      cprice,
      "sell",
      selectedOption
    )
      .then((d) => {
        if (d.status == 200) {
          NotificationManager.success(d.message);
          dispatch(
            getFutureUserBalance(user?.params ? user.params.token : user.token)
          );
          N_executeFutureOrder(
            d.result.order_id,
            user?.params ? user.params.token : user.token,
            d.result.type
          ).then((res)=>{
            if(res.status==200) {
              dispatch(
                getFutureUserBalance(user?.params ? user.params.token : user.token)
              );
              dispatch(
                getFutureUserOrder(user?.params ? user.params.token : user.token)
              );
            }
          })
        } else if (d.status == 400) {
          NotificationManager.success(d.message);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  useEffect(()=>{
    setTotal(0)
    setAmount(0)
  }, [activeTab])

  return (
    <>
      <div className="card border-0 position-relative">
        {!isLoggedIn ? (
          <div className="buysell_without_login">
            <div className="message_part">
              <h5>Want to get started?</h5>
              <p>Create an account in just a few seconds.</p>
              <div>
                <Link className="btn btn-primary py-2" to="/login">
                  Log In
                </Link>
              </div>
              <p className="mb-0 py-3">OR</p>
              <div>
                <Link className="btn btn-outline-primary" to="/create">
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        ) : null}

        <div className="card-header--  padding_0 buysell_tabs mx-2">
          <div
            className="nav nav-pills nav-justified border-bottom-0 ms-2"
            id="nav-tab"
            role="tablist"
          >
            <div
              className={`nav-item nav-link me-2 buy_tab${
                activeTab == 0 ? " active" : ""
              }`}
              id="nav-home-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-home"
              type="tab"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
              onClick={() => {
                setActiveTab(0);
              }}
            >
              <div className="link-text">Buy Long</div>
            </div>

            <div
              className={`nav-item nav-link sell_tab${
                activeTab == 1 ? " active" : ""
              }`}
              id="nav-profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-profile"
              type="tab"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
              onClick={() => {
                setActiveTab(1);
              }}
            >
              <div className="link-text">Sell Short</div>
            </div>
          </div>
        </div>
        {/* <div className="card-header">Stop Limit</div> */}

        <div className="card-body buysell_height" id="open-order">
          <div class="tab-content" id="nav-tabContent">
            <div
              class={`tab-pane${activeTab == 0 ? " active" : ""}`}
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div className="d-flex justify-content-center gap-2 mb-3">
                <div className="margin-mode">
                  <div>{future_type}</div>
                  <div>
                    <IoMdArrowDropdown />
                  </div>
                </div>
                <div className="margin-mode" onClick={handleShow}>
                  <div className="text-orange fw-bold">{leverage}x</div>
                  <div>
                    <IoMdArrowDropdown />
                  </div>
                </div>
              </div>

             

              <div className="d-flex justify-content-between mb-3">
                <div>
                  {/* Render content based on the selected option */}
                  {selectedOption === "limit" && (
                    <h5 className="fs-13 text-primary"> Limit</h5>
                  )}
                  {selectedOption === "market" && (
                    <h5 className="fs-13 text-primary">Market</h5>
                  )}
                </div>
                <div className="fs-13">
                  <select
                    className="custom-select px-2 rounded"
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                  >
                    <option value="limit">Limit</option>
                    {/* <option value="market">Market</option> */}
                  </select>
                </div>
              </div>

              <div>
                <label for="user_email" class="text-lgray">
                  Limit Price
                </label>
                <div className="input-group mb-3 exchange_input_grp">
                  {/* <span className="input-group-text buy-sell-form-bg buy-sell-theme">
                          Limit Price
                        </span> */}

                  <input
                    type="text"
                    className="form-control buy-sell-form-bg buy-sell-theme"
                    value={atPrice}
                    onChange={(e) => {
                      setATPrice(
                        e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1")
                          .replace(new RegExp(`^(\\d+\\.\\d{${spreadvalue}}).*`, "g"), "$1")
                      );
                      setTotal(
                        (e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1") * amount)*leverage
                      );
                    }}
                  />
                  <span className="input-group-text bg-transparent">
                    {coin[1].toUpperCase()}
                  </span>
                </div>

                <label for="user_email" class="text-lgray">
                  Amount
                </label>
                <div className="input-group mb-3 exchange_input_grp">
                  {/* <span className="input-group-text buy-sell-form-bg buy-sell-theme">
                      Amount
                    </span> */}

                  <input
                    type="text"
                    className="form-control buy-sell-form-bg buy-sell-theme"
                    value={amount}
                    onChange={(e) => {
                      setAmount(
                        e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1")
                      );
                      setTotal(
                        (e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1") * atPrice)*leverage
                      );
                    }}
                  />

                  <span className="input-group-text">
                    {coin[0].toUpperCase()}
                  </span>
                </div>

                <label for="user_email" class="text-lgray">
                  Total
                </label>
                <div className="input-group mb-3 exchange_input_grp">
                  {/* <span className="input-group-text buy-sell-form-bg buy-sell-theme">
                      Total
                    </span> */}

                  <input
                    type="text"
                    className="form-control buy-sell-form-bg buy-sell-theme"
                    value={total}
                    onChange={(e) => {
                      setAmount(
                        e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1") / atPrice
                      );
                      setTotal(
                        (e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1"))*leverage
                      );
                    }}
                  />

                  <span className="input-group-text">
                    {coin[1].toUpperCase()}
                  </span>
                </div>

                <div className="row mt-4 text-muted">
                  <div className="col-12 d-flex justify-content-between">
                    <div
                      className="buy_sell_amount_picker"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => {
                        setTotal(Number(buybalance ? buybalance * 0.25 : 0)*leverage); 
                        setAmount(
                          Number((buybalance ? buybalance * 0.25 : 0) / atPrice)
                        );
                      }}
                    >
                      25%
                    </div>
                    <div
                      className="buy_sell_amount_picker"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => {
                        setTotal(Number(buybalance ? buybalance * 0.5 : 0)*leverage);
                        setAmount(
                          buybalance ? (buybalance * 0.5) / atPrice : 0
                        );
                      }}
                    >
                      50%
                    </div>
                    <div
                      className="buy_sell_amount_picker"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => {
                        setTotal(Number(buybalance ? buybalance * 0.75 : 0)*leverage);
                        setAmount(
                          buybalance ? (buybalance * 0.75) / atPrice : 0
                        );
                      }}
                    >
                      75%
                    </div>
                    <div
                      className="buy_sell_amount_picker"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => {
                        setTotal((buybalance ? buybalance : 0)*leverage);
                        setAmount(buybalance ? buybalance / atPrice : 0);
                      }}
                    >
                      100%
                    </div>
                  </div>
                </div>

                <div className="row mt-4 text-muted">
                  <div className="col-lg-6">
                    {/* <span className="fs-12">
                            Buy {coin[0].toUpperCase()}
                          </span> */}
                    {coin[1] !== campare_currency
                      ? coin[1].toUpperCase()
                      : campare_currency.toUpperCase()}
                  </div>
                  <div className="col-lg-6 text-end fs-12">
                    {/* <span className="mx-2" title="wallet">
                            <i className="fa fa-wallet"> </i>
                          </span> */}
                    {coin[1] !== campare_currency
                      ? buybalance + " " + coin[1].toUpperCase()
                      : buybalance + " "}
                  </div>
                </div>

                <div class="form-check mt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    // value=""
                    id="profitloss"
                    onClick={handleShowProfiltmodal}
                  />
                  <label class="form-check-label" for="profitloss">
                    Take Profit/ Stop Loss
                  </label>
                </div>

                {/* TP/SL */}

              
                <div className="d-grid mt-3">
                  <button
                    className="btn btn-success py-2"
                    disabled={bloading}
                    onClick={() => {
                      if (isLoggedIn) {
                        buyCoin(atPrice, amount, coin[0], coin[1]);
                      } else {
                        NotificationManager.error(
                          "First login then perform buy/sell"
                        );
                      }
                    }}
                  >
                    {bloading ? (
                      <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                    ) : null}
                    BUY Long
                  </button>
                </div>
              </div>
              
            </div>
            <div
              class={`tab-pane${activeTab == 1 ? " active" : ""}`}
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <div className="d-flex justify-content-center gap-2 mb-3">
                <div className="margin-mode">
                  <div>{future_type}</div>
                  <div>
                    <IoMdArrowDropdown />
                  </div>
                </div>
                <div className="margin-mode" onClick={handleShow}>
                  <div className="text-orange fw-bold">{leverage}x</div>
                  <div>
                    <IoMdArrowDropdown />
                  </div>
                </div>
              </div>

             

              <div className="d-flex justify-content-between mb-3">
                <div>
                  {/* Render content based on the selected option */}
                  {selectedOption === "limit" && (
                    <h5 className="fs-13 text-primary"> Limit</h5>
                  )}
                  {selectedOption === "market" && (
                    <h5 className="fs-13 text-primary">Market</h5>
                  )}
                </div>
                <div className="fs-13">
                  <select
                    className="custom-select px-2 rounded"
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                  >
                    <option value="limit">Limit</option>
                    {/* <option value="market">Market</option> */}
                  </select>
                </div>
              </div>

              <div>
                <label for="user_email" class="text-lgray">
                  Limit Price
                </label>
                <div className="input-group mb-3 exchange_input_grp">
                  {/* <span className="input-group-text buy-sell-form-bg buy-sell-theme">
                          Limit Price
                        </span> */}

                  <input
                    type="text"
                    className="form-control buy-sell-form-bg buy-sell-theme"
                    value={atPrice}
                    onChange={(e) => {
                      setATPrice(
                        e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1")
                          .replace(new RegExp(`^(\\d+\\.\\d{${spreadvalue}}).*`, "g"), "$1")
                      );
                      setTotal(
                        (e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1") * amount)*leverage
                      );
                    }}
                  />
                  <span className="input-group-text bg-transparent">
                    {coin[1].toUpperCase()}
                  </span>
                </div>

                <label for="user_email" class="text-lgray">
                  Amount
                </label>
                <div className="input-group mb-3 exchange_input_grp">
                  {/* <span className="input-group-text buy-sell-form-bg buy-sell-theme">
                      Amount
                    </span> */}

                  <input
                    type="text"
                    className="form-control buy-sell-form-bg buy-sell-theme"
                    value={amount}
                    onChange={(e) => {
                      setAmount(
                        e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1")
                      );
                      setTotal(
                        (e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1") * atPrice)*leverage
                      );
                    }}
                  />

                  <span className="input-group-text">
                    {coin[0].toUpperCase()}
                  </span>
                </div>

                <label for="user_email" class="text-lgray">
                  Total
                </label>
                <div className="input-group mb-3 exchange_input_grp">
                  {/* <span className="input-group-text buy-sell-form-bg buy-sell-theme">
                      Total
                    </span> */}

                  <input
                    type="text"
                    className="form-control buy-sell-form-bg buy-sell-theme"
                    value={total}
                    onChange={(e) => {
                      setAmount(
                        e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1") / atPrice
                      );
                      setTotal(
                        (e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1"))*leverage
                      );
                    }}
                  />

                  <span className="input-group-text">
                    {coin[1].toUpperCase()}
                  </span>
                </div>

                <div className="row mt-4 text-muted">
                  <div className="col-12 d-flex justify-content-between">
                    <div
                      className="buy_sell_amount_picker"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => {
                        setTotal(Number(buybalance ? buybalance * 0.25 : 0)*leverage); 
                        setAmount(
                          Number((buybalance ? buybalance * 0.25 : 0) / atPrice)
                        );
                      }}
                    >
                      25%
                    </div>
                    <div
                      className="buy_sell_amount_picker"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => {
                        setTotal(Number(buybalance ? buybalance * 0.5 : 0)*leverage);
                        setAmount(
                          buybalance ? (buybalance * 0.5) / atPrice : 0
                        );
                      }}
                    >
                      50%
                    </div>
                    <div
                      className="buy_sell_amount_picker"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => {
                        setTotal(Number(buybalance ? buybalance * 0.75 : 0)*leverage);
                        setAmount(
                          buybalance ? (buybalance * 0.75) / atPrice : 0
                        );
                      }}
                    >
                      75%
                    </div>
                    <div
                      className="buy_sell_amount_picker"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => {
                        setTotal(Number(buybalance ? buybalance : 0)*leverage);
                        setAmount(buybalance ? buybalance / atPrice : 0);
                      }}
                    >
                      100%
                    </div>
                  </div>
                </div>

                <div className="row mt-4 text-muted">
                  <div className="col-lg-6">
                    {/* <span className="fs-12">
                            Buy {coin[0].toUpperCase()}
                          </span> */}
                    {coin[1] !== campare_currency
                      ? coin[1].toUpperCase()
                      : campare_currency.toUpperCase()}
                  </div>
                  <div className="col-lg-6 text-end fs-12">
                    {/* <span className="mx-2" title="wallet">
                            <i className="fa fa-wallet"> </i>
                          </span> */}
                    {coin[1] !== campare_currency
                      ? buybalance + " " + coin[1].toUpperCase()
                      : buybalance + " "}
                  </div>
                </div>

                <div class="form-check mt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    // value=""
                    id="profitloss"
                    onClick={handleShowProfiltmodal}
                  />
                  <label class="form-check-label" for="profitloss">
                    Take Profit/ Stop Loss
                  </label>
                </div>

                {/* TP/SL */}

              
                <div className="d-grid mt-3">
                    <button
                      className="btn btn-danger py-2"
                      disabled={loading}
                      onClick={() => {
                        if (isLoggedIn) {
                          sellCoin(atPrice, amount, coin[0], coin[1]);
                        } else {
                          NotificationManager.error(
                            "First login then perform buy/sell"
                          );
                        }
                      }}
                    >
                      {loading ? (
                        <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                      ) : null}
                      SELL Short
                    </button>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-3">

        {isLoggedIn ? (
          <>
            <h6 className="mb-3 mt-3">Inverse Derivatives Account</h6>

            <p>Derivatives Trade</p>

            <div class="flex_row  mt-3">
              <div>
                <HtmlTooltip
                  title="The amount of this asset you hold in your Unified Trading Account."
                  arrow
                >
                  <div className="tooltip_text">
                    {coin[1].toUpperCase()} Balance
                  </div>
                </HtmlTooltip>
              </div>
              <div>
                {buybalance} {coin[1].toUpperCase()}{" "}
              </div>
            </div>

            <div className="d-flex gap-2">
              <Link to="/AssetsDeposit" className="trade_btn">
                Deposit
              </Link>
              <Link to="/AssetsWithdraw" className="trade_btn">
                Withdraw
              </Link>
            </div>
          </>
        ) : null}
      </div>
       {/* Leverage Modal */}

       <Modal
                centered
                show={show}
                backdrop="static"
                onHide={handleClose}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Adjust Leverage </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <label for="Leverage" class="text-lgray mb-2">
                    Leverage
                  </label>
                  <div className="">
                    <input type="text" class="input__container" value={cv+"x"} />
                  </div>
                  <div className="px-3 my-4">
                    <Slider
                      aria-label="Temperature"
                      defaultValue={10}
                      valueLabelDisplay="auto"
                      min={10}
                      max={50}
                      value={cv}
                      onChange={(e)=>{
                        setCV(e.target.value)
                      }}

                      color="success"
                    />
                  </div>

                  <div className="d-flex gap-2 mt-3">
                    <div className="col-6">
                      <div className="d-grid">
                        <button className="btn btn-primary" onClick={()=>{
                          setLeverage(cv)
                          handleClose()
                        }}>Confirm</button>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-grid">
                        <button className="btn btn-light" onClick={()=>{
                          setCV(leverage)
                          handleClose()
                          }}>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              {/* cross Modal */}

              <Modal
                size="lg"
                centered
                show={showCross}
                backdrop="static"
                onHide={handleCloseCross}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Choose Margin Mode </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-5">
                  <div className="row">
                    <div className="col-lg-4 col-6">
                      <div className="custom_card">
                        <div>
                          <div>
                            <h4>Cross Margin</h4>
                            <p className="text-warning">
                            (Regular User)
                            </p>
                            <button className="btn btn-primary">
                              Setting
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-6">
                      <div className="custom_card">
                        <h4>Isolated Margin</h4>
                        <p className="text-warning">
                        (Regular User)
                        </p>
                        <button className="btn btn-primary">
                              Setting
                            </button>
                      </div>
                    </div>
                    <div className="col-lg-4 col-6">
                      <div className="custom_card">
                        <div>
                          <h4>Portfolio Margin</h4>
                          <p className="text-warning">
                          (Professional Derivatives Trader)
                          </p>
                          <button className="btn btn-primary">
                              Setting
                            </button>
                        </div>
                      </div>

                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              <Modal
                  centered
                  show={showProfiltmodal}
                  backdrop="static"
                  onHide={handleCloseProfiltmodal}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Add TP / SL </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div class="d-flex justify-content-between mb-4">
                      <div class="">
                        <div class="text-lgray">Order Price</div>
                        <div class="tpsl-new__price-header-value">
                          <span>{atPrice}</span>
                        </div>
                      </div>
                      <div class="">
                        <div class="text-lgray">Qty</div>
                        <div class="tpsl-new__price-header-value">{amount}</div>
                      </div>
                      <div class="">
                        <div class="text-lgray">Market Price</div>
                        <div class="tpsl-new__price-header-value">
                          {cprice}
                        </div>
                      </div>
                    </div>
                    <div className="">
                        <div className="mb-4">
                          <label for="Leverage" class="text-lgray mb-2">
                            Take Profit
                          </label>

                          <div className="row">
                            <div className="col-lg-12">
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control buy-sell-form-bg buy-sell-theme"
                                  placeholder="Trigger Price"
                                  onChange={(e)=>{
                                    setStopProfit(
                                    e.target.value
                                    .replace(/[^0-9.]/g, "")
                                    .replace(/(\..*?)\..*/g, "$1"))
                                    .replace(new RegExp(`^(\\d+\\.\\d{${spreadvalue}}).*`, "g"), "$1")
                                  }}
                                />
                                {/* <span className="input-group-text">
                                  <GoPlus className="mx-2" onClick={{}} />
                                  <HiOutlineMinusSm
                                    className="mx-2"
                                    onClick={{}}
                                  />
                                </span> */}
                                <button
                                  class="btn input_drpdwon dropdown-toggle"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  Last
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end">
                                  <li>
                                    <a class="dropdown-item" href="#">
                                      Index
                                    </a>
                                  </li>
                                  <li>
                                    <a class="dropdown-item" href="#">
                                      Last
                                    </a>
                                  </li>
                                  <li>
                                    <a class="dropdown-item" href="#">
                                      Mark
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="small text-muted mb-3">
                            The Take Profit price must be higher than the order
                            price
                          </div>
                        </div>

                        <div>
                          <label for="Leverage" class="text-lgray mb-2">
                            Stop Loss
                          </label>

                          <div className="row  mb-3">
                            <div className="col-lg-12">
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control buy-sell-form-bg buy-sell-theme"
                                  placeholder="Trigger Price"
                                  onChange={(e)=>{
                                    setStopLoss(
                                    e.target.value
                                    .replace(/[^0-9.]/g, "")
                                    .replace(/(\..*?)\..*/g, "$1"))
                                    .replace(new RegExp(`^(\\d+\\.\\d{${spreadvalue}}).*`, "g"), "$1")
                                  }}

                                />
                                {/* <span className="input-group-text">
                                  <GoPlus className="mx-2" onClick={{}} />
                                  <HiOutlineMinusSm
                                    className="mx-2"
                                    onClick={{}}
                                  />
                                </span> */}
                                <button
                                  class="btn input_drpdwon dropdown-toggle"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  Last
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end">
                                  <li>
                                    <a class="dropdown-item" href="#">
                                      Last
                                    </a>
                                  </li>
                                  <li>
                                    <a class="dropdown-item" href="#">
                                      Mark
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex gap-2 mt-3">
                          <div className="col-6">
                            <div className="d-grid">
                              <button className="btn btn-primary"
                              onClick={handleCloseProfiltmodal}
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="d-grid">
                              <button
                                className="btn btn-light"
                                onClick={()=>{
                                  setStopLoss(0);
                                  setStopProfit(0)
                                  handleCloseProfiltmodal()
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                        </div>
                  </Modal.Body>
                </Modal>

    </>
  );
}
