import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import AssetSidebar from "./components/AssetSidebar";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { FaCoins, FaSortDown, FaSortUp } from "react-icons/fa";
import {
  N_crypto_withdraw_Otp_Email,
  N_inr_withdraw,
  N_transectionHistory,
} from "./redux/helpers/api_functions_new";
import { Link } from "react-router-dom";
import { user_logout } from "./redux/actions/authActions";
import OTPInput from "react-otp-input";
import { LuSendHorizonal } from "react-icons/lu";
export default function AssetsFiatWithdraw(props) {
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isOTPVerified, setIsOTPVerified] = useState();
  const [transection_id, setTransectionId] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [wallettype, setwallettype] = useState();
  const [wallet_data, setWalletData] = useState();
  const [withdrawhistory, setWithdrawHistory] = useState();
  const [remark, setRemark] = useState();
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [motp, setMOtp] = useState();
  const [eotp, setEOtp] = useState();
  const { user, profile } = useSelector((state) => state.AuthReducer);
  const { wallet } = useSelector((state) => state.coinDBReducer);
  const [isError, SetIsError] = useState('')
  const [loading, setLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [controller, setController] = useState(null);
  const [otpcontroller, setOtpController] = useState(null);
  const dispatch = useDispatch();


  useEffect(() => {
    const walletd = wallet.find((item) => item.symbol == "INR");
    setWalletData(walletd);
  }, [wallet]);
  useEffect(() => {
    const token = user?.params ? user.params.token : user.token;
    let data = {
      type:"withdrawal",
      symbol: "INR",
    };
    N_transectionHistory(data, token)
      .then((data) => {
        if (data.status === 200) {
          setWithdrawHistory(data?.result);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  return (
    <>
      <Header />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
          <div className="container">
            <div className="marginTop">
              <div className="account_page">
                <div className="col-lg-12">
                  <div className="flex_row mb-4">
                    <div className="">
                      <div class="">
                        <h3 className=""> Withdraw Fiat </h3>
                      </div>
                    </div>
                    <div className="">
                      <Link to="/AssetsWithdraw" className="btn btn-light">
                        Withdraw Crypto{" "}
                        <i class="fa-solid fa-arrow-right-long ms-1"></i>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-7">
                    <div className="cstm_form">
                      <div class="row_flex_div mb-4">
                        <div class="col_left">
                          <label for="inputPassword6" class="">
                            Select Coin
                          </label>
                        </div>
                        <div class="col_right">
                          <div className="select_box">
                            {" "}
                            <div
                              className="select_box_inner"
                            // data-bs-toggle="modal"
                            // data-bs-target="#coin_modal"
                            >
                              <div class="d-flex align-items-center">
                                <div>
                                  <img
                                    src="https://dtbx.exchange/rupee.png"
                                    alt="coin symbole"
                                    class="coin_img me-2"
                                  />
                                </div>
                                <div class="product_name">
                                  INR{" "}
                                  <span className="unit text-muted">RUPEE</span>
                                </div>{" "}
                              </div>
                            </div>
                            {/* <div>
                              {" "}
                              <img
                                src="https://assets.staticimg.com/kucoin-main-web/2.0.20/svg/arrow.267b17d2.svg"
                                class="arrow_img"
                                alt="arrow-icon"
                              />
                            </div> */}
                          </div>
                        </div>
                      </div>

                      <div class="row_flex_div align-items-start ">
                        <div class="col_left d-lg-block d-none">
                          Withdraw to
                        </div>
                        <div class="col_right">
                          <div className="cstm_form">
                            <div className="mb-4">
                              <label class="small text-muted">
                                Withdrawal Amount
                              </label>
                              <div class="input-group">
                                <input
                                  type="number"
                                  class="form-control"
                                  placeholder="Enter Withdrawal Amount"
                                  value={withdrawAmount}
                                  onChange={(e) => {
                                    if (e.target.value < 100) {
                                      SetIsError("Withdrawal amount must be at least 100")
                                    } else {
                                      SetIsError('')
                                    }
                                    setWithdrawAmount(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            {isError && (
                              <div className="text-danger mt-2">
                                {isError}
                              </div>
                            )}
                            <div className="mb-4">
                              <label className="small text-muted">
                                Payment Method
                              </label>
                              <div className="input-group">
                                <select
                                  className="form-control"
                                  value={paymentMethod}
                                  onChange={(e) => {
                                    setPaymentMethod(e.target.value);
                                  }}
                                >
                                  <option value="bank">Bank</option>
                                  {/* <option value="upi">UPI</option> */}
                                </select>
                              </div>
                            </div>
                            <div className="mb-4">
                              <label class="small text-muted">Remark</label>
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="remark"
                                  value={remark}
                                  onChange={(e) => {
                                    setRemark(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <section>
                            <div className="all_value_grid">
                              <div class="all_value_col">
                                <h6 class="mb-0  fs-12 text-muted">
                                  Available Balance
                                </h6>
                                <span class="">
                                  {wallet_data?.balance - wallet_data?.locked}
                                </span>
                                <span class="text-muted ms-1">
                                  {wallet_data?.symbol}
                                </span>
                              </div>
                              {/* <div class="all_value_col">
                            <h6 class="mb-0  fs-12 text-muted">
                              Minimum Withdrawal
                            </h6>
                            <span class="">0.00</span>
                            <span class="text-muted ms-1">BNB</span>
                          </div> */}
                              <div class="all_value_col">
                                <h6 class="mb-0  fs-12 text-muted">Fee</h6>
                                <span class="">
                                  {wallet_data?.withdrawal_fee}
                                </span>
                                <span class="ms-1 text-muted">
                                  {wallet_data?.symbol}
                                </span>
                              </div>
                              {/* <div class="all_value_col">
                            <h6 class="mb-0  fs-12 text-muted">
                              24h Withdrawal Limit
                            </h6>
                            <span class="">1,000,000.00</span>
                            <span class="text-muted ms-1">USDT</span>
                          </div> */}
                            </div>
                          </section>
                          <div className="d-grid">
                            <button
                              className="btn btn-primary btn-lg"
                              data-bs-toggle={withdrawAmount >= 100 ? 'modal' : ''}
                              data-bs-target="#withdrawal_modal"
                              disabled={withdrawAmount < 100}
                            >
                              Withdrawal
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1"></div>
                  <div class="col-lg-4">
                    <div className="jambotron-outline">
                      <div className="account_page_subheading mb-2">
                        Without KYC
                      </div>
                      <span class="badge bg-light border rounded-pill me-2 fs-14 mb-2">
                        <span class="fa fa-circle-check text-green me-2"></span>{" "}
                        Deposit
                      </span>{" "}
                      <span class="badge bg-light border rounded-pill me-2 fs-14 mb-2">
                        <span class="fa fa-circle-check text-green me-2"></span>{" "}
                        Trade
                      </span>{" "}
                      <span class="badge bg-light border rounded-pill me-2 fs-14 mb-2">
                        <span class="fa-solid fa-circle-xmark text-secondary  me-2"></span>{" "}
                        Withdrawal
                      </span>{" "}
                      <span class="badge bg-light border rounded-pill me-2 fs-14 mb-2">
                        <span class="fa-solid fa-circle-xmark text-secondary  me-2"></span>{" "}
                        P2P
                      </span>
                    </div>
                    <div className="jambotron-outline mt-3">
                      <div className="account_page_subheading mb-2">
                        With KYC
                      </div>

                      <span class="badge bg-light border rounded-pill me-2 fs-14 mb-2">
                        <span class="fa fa-circle-check text-green me-2"></span>{" "}
                        Deposit
                      </span>

                      <span class="badge bg-light border rounded-pill me-2 fs-14 mb-2">
                        <span class="fa fa-circle-check text-green me-2"></span>{" "}
                        Trade
                      </span>

                      <span class="badge bg-light border rounded-pill me-2 fs-14 mb-2">
                        <span class="fa fa-circle-check text-green me-2"></span>{" "}
                        Withdrawal
                      </span>

                      <span class="badge bg-light border rounded-pill me-2 fs-14 mb-2">
                        <span class="fa fa-circle-check text-green me-2"></span>{" "}
                        P2P
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 mt-5">
                  <div className="flex_row mb-4">
                    <div className="">
                      <div class="">
                        <h4 className=""> Recent Withdrawals </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="overflow-auto mt-3">
                  <div className="table_scroll_div">
                    <table className="table global_table">
                      <thead>
                        <tr>
                          <th>Symbol</th>
                          <th>Amount</th>
                          <th>Fee</th>
                          <th>Remark</th>
                          <th>Transection Id</th>
                          <th>Status</th>
                          <th class="text-end">Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {withdrawhistory && withdrawhistory.length > 0 ? (
                          withdrawhistory.map((item) => {
                            return (
                              <tr>
                                <td>{item?.symbol}</td>
                                <td>{item?.amount}</td>
                                <td>{item?.transaction_fee}</td>
                                <td>{item?.remark}</td>
                                <td>{item?.uniqueness}</td>
                                <td>
                                  {item.status == 1
                                    ? "Completed"
                                    : item.status == -2
                                      ? "Rejected"
                                      : "Pending"}
                                </td>

                                <td class="text-end">
                                  {new Date(item.updatedAt).toLocaleString()}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              <img
                                src="/img/no-data.png"
                                className="no-data-found"
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* =============Use this div When data is not configure================ */}

                {/* <div className="col-lg-12">
              <div className="aco_box">
                <div className="page-content-title text-center">
                  <h3 className="">Withdraw Crypto</h3>
                </div>
                <div className="aco_hero mt-4">
                  <div class="pb-3">
                    <h4 className="fw-bold">
                      You must configure the security settings of your account
                      before withdrawal.
                    </h4>
                  </div>

                  <div className="text-lgray">
                    <p>
                      <i className="fa fa-exclamation-circle me-2 text-warning"></i>
                      Set trading password. <a href="#">Configure</a>
                    </p>
                    <p>
                      {" "}
                      <i className="fa fa-exclamation-circle me-2 text-warning"></i>
                      Bind mobile phone or add Google Verification.{" "}
                      <a href="#">Enable</a>
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
                {/* =============When data is not configure================ */}
              </div>
            </div>
          </div>
        </main>
      </div>

      <div class="modal fade" tabindex="-1" id="withdrawal_modal">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-bottom-0">
              <h4 class="modal-title">Withdrawal</h4>
              <button
                type="button"
                class="btn-close fs-14"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-4">
              <div className="error_div">
                {setErrorMessage && (
                  <h6 className="mb-0 fs-14 text-danger">{errorMessage}</h6>
                )}
                {successMessage && (
                  <h6 className="fs-14 text-success">{successMessage}</h6>
                )}
              </div>
              <div class="cstm_form mb-4">
                {/* <label class="text-muted mb-1">Your Email </label> */}
                <h6 className="mb-2 text-lgray">
                  {" "}
                  A verification code will be sent to your email
                </h6>

                <div className="form-group mb-4">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control text-muted"
                      disabled={isOTPSent}
                      readOnly
                      value={profile?.email}
                    />
                    <span class="input-group-text">
                      <div
                        className="text-lgray cursor_pointer"
                        onClick={() => {
                          if (withdrawAmount) {
                            if (controller) {
                              controller.abort();
                            }
                        
                            // Create a new controller for the new request
                            const newController = new AbortController();
                            setController(newController);
                            N_inr_withdraw(
                              user?.params ? user.params.token : user.token,
                              withdrawAmount,
                              "INR",
                              remark,
                              paymentMethod,
                              newController
                            ).then((data) => {
                            if (data.status === 404) {
                              dispatch(
                                user_logout(() => {
                                  props?.history?.push("/login");
                                  console.log("abdsfjh");
                                })
                              );
                            }
                            if (data.status == 200) {
                              // NotificationManager.success(data.message);
                              setSuccessMessage(data.message);
                              setTimeout(() => {
                                setSuccessMessage("");
                              }, 4000);
                              setIsOTPSent(true);
                              setTransectionId(data?.params.transection_id);
                              setwallettype(data?.params.symbol);
                            } else {
                              // NotificationManager.error(data.message);
                              setErrorMessage(data.message);
                              setTimeout(() => {
                                setErrorMessage("");
                              }, 4000);
                            }
                          });
                        }}
                      }
                      >
                        {isOTPSent ? (
                          <>Resend Code</>
                        ) : (
                          <>
                            <LuSendHorizonal className="fs-4" />
                          </>
                        )}
                      </div>
                    </span>
                  </div>
                </div>

                {isOTPSent ? (
                  <>
                    <div className="text-center my-4">
                      <h4 className="hero_heading mb-3 mt-4">
                        Enter the 6- digit code sent to {profile?.email}
                      </h4>

                      <div className="d-flex justify-content-center mb-2">
                        <OTPInput
                          value={motp}
                          onChange={(otp) => {
                            if (/^\d+$/.test(otp) || otp === "") {
                              setMOtp(otp);
                            }
                          }}
                          type="tel"
                          pattern="[0-9]*"
                          numInputs={6}
                          renderSeparator={
                            <span
                              style={{
                                fontSize: "7px",
                                marginLeft: "8px",
                                marginRight: "8px",
                              }}
                            ></span>
                          }
                          isDisabled={loading}
                          renderInput={(inputProps, index) => (
                            <input
                              {...inputProps}
                              key={index}
                              style={{
                                width: "40px",
                                textAlign: "center",
                                marginBottom: "10px",
                                height: "40px",
                                borderStyle: "solid",
                                borderWidth: "1px",
                                borderColor: "#ddd",
                                color: "#000",
                                fontWeight: 600,
                                borderRadius: "5px",
                                backgroundColor: "#fff",
                                outline: "none",
                              }}
                            />
                          )}
                        />
                      </div>
                      <button
                        className="btn btn-primary"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={(e) => {
                          if (otpcontroller) {
                            otpcontroller.abort();
                          }
                      
                          // Create a new controller for the new request
                          const newController = new AbortController();
                          setOtpController(newController);
                          N_crypto_withdraw_Otp_Email(
                            user?.params ? user.params.token : user.token,
                            motp,
                            transection_id,
                            wallettype,
                            newController
                          ).then((dt) => {
                            // NotificationManager.success(dt.message);
                            setErrorMessage(dt.message);
                            setTimeout(() => {
                              document.location.reload();
                            }, 200);
                          });
                        }}
                      >
                        Verify code
                      </button>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
