import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
import Carousel from "react-grid-carousel";
import { FaChartLine, FaCoins, FaTelegram } from "react-icons/fa";
import { BiGitCompare } from "react-icons/bi";
import { HiCubeTransparent } from "react-icons/hi";
import { FiUser, FiGlobe, FiUsers } from "react-icons/fi";
import { SlUser } from "react-icons/sl";
import { GiReceiveMoney } from "react-icons/gi";
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
  IoShareSocialOutline,
} from "react-icons/io5";

import Header from "./Header";
import Footer from "./Footer";
import {
  N_checkKYCStatus,
  N_getRefferalData,
  WebsiteURL,
} from "../redux/helpers/api_functions_new";
import { NotificationManager } from "react-notifications";
import { Helmet } from "react-helmet";
import { LiaMoneyBillSolid } from "react-icons/lia";
import { MdSecurity } from "react-icons/md";
import FooterNav from "./FooterNav";
import CalltoAction from "./CalltoAction";
import {
  BsCopy,
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsTelegram,
  BsTwitter,
  BsYoutube,
} from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import { RiWhatsappFill } from "react-icons/ri";
import { CiMoneyCheck1 } from "react-icons/ci";
import { Link } from "react-router-dom/cjs/react-router-dom";

export default function AffiliateHome(props) {
  const { user, isLoggedIn } = useSelector((state) => state.AuthReducer);
  const { webData } = useSelector((state) => state.websiteDBReducer);
  const [referralKey, setReferralKey] = useState(null);
  const [copyurl, setcopyurl] = useState("Copy");
  const [copyKey, setCopyKey] = useState("Copy");
  const [referralUserData, setReferralUserData] = useState(null);
  const [referralUserEarning, setReferralUserEarning] = useState(null);
  const [isverified, setIsverified] = useState(0);
  const totalIncome =
    (webData?.level_income?.[1] ?? 0) +
    (webData?.level_income?.[2] ?? 0) +
    (webData?.level_income?.[3] ?? 0);

  const handleCopyClick = (key, action) => {
    copyToClipboard(key, action);
  };

  useEffect(() => {
    if (isLoggedIn) {
      N_getRefferalData(user?.params ? user.params.token : user.token).then(
        (d) => {
          if (d.status) {
            const key = d?.params?.referral_code;
            if (key) setReferralKey(key);
            const total_ref = d?.params?.total_referals;
            const total_earn = d?.params?.total_referal_earning;

            if (total_ref && total_ref.length > 0) {
              setReferralUserData(total_ref);
            }
            if (total_earn && total_earn.length > 0) {
              setReferralUserEarning(total_earn);
            }
          }
        }
      );
      N_checkKYCStatus(user?.params ? user.params.token : user.token).then(
        (d) => {
          if (d.status === 200) {
            if (d?.params.status == 1) {
              setIsverified(1);
            }
          }
        }
      );
    }
  }, [isLoggedIn]);
  const copyToClipboard = (text, action) => {
    if (!text) return; // Don't copy if the text is empty
    navigator.clipboard.writeText(text).then(
      () => {
        action == "url" ? setcopyurl("Copied") : setCopyKey("Copied");
      },
      (err) => {
        console.error("Failed to copy: ", err);
      }
    );
  };

  return (
    <>
      <div className="application">
        <Helmet>
          <meta
            name="description"
            content={`Join ${webData?.website_title} Affiliate Program for exclusive rewards. Refer friends and earn exciting benefits. Elevate your crypto journey with ${webData?.website_title}.`}
          />
          <title>
            Referral Program: Earn Free Crypto with{" "}
            {`${webData?.website_title}`}{" "}
          </title>
        </Helmet>
      </div>
      <Header />
      <div className="page-content">
        <div className="inner_page_banner">
          <div className="container">
            <div className="row spacer-sm">
              <div className="col-12 col-lg-8 col-md-6 col-sm-12 mb-3">
                <div className="spacer-sm">
                  <h4 className="page_main_heading">
                    <span className="text-highlight"> Unlock Rewards </span>with
                    <br /> {webData?.website_title} Exchange Referral Program
                  </h4>
                  <p>
                    Join our referral program at {webData?.website_title}{" "}
                    Exchange and start earning lucrative commissions for every
                    trade made by your referrals, with the potential to earn up
                    to{" "}
                    {(webData?.level_income?.[1] ?? 0) +
                      (webData?.level_income?.[2] ?? 0) +
                      (webData?.level_income?.[3] ?? 0)}
                    % across three levels!
                  </p>
                  {isLoggedIn && !isverified ? (
                    <>
                      <Link to="/AccountVerification" className="action-btn">
                        Verify KYC
                      </Link>
                      {/* <span className="" */}
                      <span className="ms-3 text-muted d-inline-flex ">
                        Your KYC is not verified
                      </span>
                    </>
                  ) : !isLoggedIn ? (
                    <>
                      <div>
                        You are just steps away from recieving your reward.
                        <div className="mb-3 text-muted">
                          Sign up and start earning.
                        </div>
                        <Link to="/create" className="action-btn">
                          SIGN UP NOW
                        </Link>
                      </div>
                    </>
                  ) : null}

                  {isLoggedIn ? (
                    <div class="row hero_stats">
                      <div class="col-lg-4 col-6">
                        <div class="fs-3 fw-bold">
                          {referralUserData?.length > 0
                            ? referralUserData?.length
                            : 0}
                        </div>
                        <div class="hero_text">Total Refer Friends</div>
                      </div>
                      {referralUserEarning ? (
                        referralUserEarning?.map((item) => (
                          <div class="col-lg-4 col-6">
                            <div class="fs-3 fw-bold">
                              {item?.total} {item?._id}
                            </div>
                            <div class="hero_text">Total Commission Earned</div>
                          </div>
                        ))
                      ) : (
                        <div class="col-lg-4 col-6">
                          <div class="fs-3 fw-bold">0 </div>
                          <div class="hero_text">Total Commission Earned</div>
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              {isLoggedIn ? (
                <div className="col-12 col-lg-4 col-md-6 col-sm-12">
                  <div class="custom_card cstm_form mt-2 mb-2">
                    <div className="card-body">
                      <div className="form-group">
                        <label>Referral Link</label>
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control"
                            value={`${WebsiteURL}/create/${referralKey}`}
                            placeholder="https://dtbx.exchange/create/5656965"
                          />
                          <span
                            class="input-group-text pointer"
                            onClick={() => {
                              handleCopyClick(
                                WebsiteURL + "/create/" + referralKey,
                                "url"
                              );
                            }}
                          >
                            <BsCopy className="me-2" />
                            {copyurl}
                          </span>
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Referral Code</label>
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control"
                            value={referralKey}
                            placeholder="5593449"
                          />
                          <span
                            class="input-group-text pointer"
                            onClick={() => {
                              handleCopyClick(referralKey, "key");
                            }}
                          >
                            <BsCopy className="me-2" />
                            {copyKey}
                          </span>
                        </div>
                      </div>
                      <div className="py-3">
                        <h4 className="hero_heading mb-0">
                          Invite and Share Via
                        </h4>
                        <hr />
                        <div className="d-flex">
                          <a
                            className="icon-link round-corner twitter"
                            href=""
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="social_btn">
                              <AiFillTwitterCircle />
                            </div>
                          </a>

                          <a
                            className="icon-link round-corner facebook"
                            href=""
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="social_btn">
                              <BsFacebook />
                            </div>
                          </a>

                          <a
                            className="icon-link round-corner whatsapp"
                            href="#"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="social_btn">
                              <RiWhatsappFill />
                            </div>
                          </a>

                          <a
                            className="icon-link round-corner telegram"
                            href=""
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="social_btn">
                              <FaTelegram />
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {/* <div className="spacer ">
          <div className="container">
            <div class="row align-items-center">
              <div class="col-lg-4 col-md-6 col-12 d-flex justify-content-center ">
                <img src="/img/pngwing.com-1.png" class="img-fluid" />
              </div>
              <div class="col-lg-8 col-md-6 mb-5">
                <h2 class="para_heading">
                  {" "}
                  The Highest Commissions in the Industry{" "}
                </h2>

                <div className="row mt-5">
                  <div className="col-lg-4">
                    <FiUsers className="fs-3 mb-4 text-lgray" />
                    <h3>5,000+</h3>
                    <p className="lead text-lgray">Number of Affiliate</p>
                  </div>
                  <div className="col-lg-4">
                    <HiCubeTransparent className="fs-3 mb-4 text-lgray" />
                    <h3>1,00,000+ </h3>
                    <p className="lead text-lgray">Commission Paid Out </p>
                  </div>
                  <div className="col-lg-4">
                    <FiGlobe className="fs-3 mb-4 text-lgray" />
                    <h3>5+</h3>
                    <p className="lead text-lgray">Countries</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="page_spacer spacer">
          <div className="container">
            <div className="para_heading text-center">
              Invite Your Friends and Start Earning Together!
            </div>
            <div className="row d-flex align-center justify-content-center mt-5">
              <div className="col-lg-4 col-md-6 col-12 padding_0">
                <div className="card bg-transparent border-0 text-center">
                  <div className="card-body padding_5 card-align">
                    <div>
                      <div class="text-center mb-3">
                        <div class="vtimeline_no active"> 1</div>
                        <div class="vtimeline_right"></div>
                      </div>

                      <h5 className="refer_heading">
                        <SlUser className="fs-4" /> Sign up{" "}
                      </h5>
                      <p className="text-lgray">
                        Register for an account on {webData?.website_title}{" "}
                        Exchange or log in if you're already a member.
                      </p>
                    </div>
                    <div className=" bg-soft-gray">
                      21 {webData?.airdrop_coin} + 100000 SHIB
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 padding_0">
                <div className="card bg-transparent border-0 text-center">
                  <div className="card-body padding_5 card-align">
                    <div>
                      <div class="text-center mb-3">
                        <div class="vtimeline_left"></div>
                        <div class="vtimeline_no "> 2</div>
                        <div class="vtimeline_right"></div>
                      </div>

                      <h5 className="refer_heading">
                        {" "}
                        <IoShareSocialOutline className="fs-4" /> Share and
                        Invite
                      </h5>
                      <p className="text-lgray">
                        Share your referral link via social media, email, or any
                        other platform where your audience resides.
                      </p>
                    </div>
                    <div className=" bg-soft-gray">
                      2 {webData?.referral_coin}{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 padding_0">
                <div className="card bg-transparent border-0 text-center">
                  <div className="card-body padding_5 card-align">
                    <div>
                      <div class="text-center mb-3">
                        <div class="vtimeline_left"></div>
                        <div class="vtimeline_no "> 3</div>
                      </div>

                      <h5 className="refer_heading">
                        {" "}
                        <CiMoneyCheck1 className="fs-4" /> Earn Commissions
                      </h5>
                      <p className="text-lgray">
                        As your referrals begin trading on{" "}
                        {webData?.website_title} Exchange, you'll earn
                        commissions on every trade they make.
                      </p>
                    </div>
                    <div className="custom_badge bg-soft-gray">
                      More you refer, more you earn
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="huge-container">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-4">
                <div class="pe-5">
                  <h2>
                    Unlock Exciting <br /> Benefits by
                    <br /> Referring Your Friends!
                  </h2>
                  <p className="text-lgray">
                    Seize the opportunity to create a brighter financial future
                    for yourself and your network.
                  </p>
                </div>
              </div>
              <div class="col-lg-4">
                <ul class="feature-list">
                  <li class="feature-list-item">
                    <span class="feature-list-icon">
                      <i
                        aria-hidden="true"
                        class="fas fa-long-arrow-alt-right"
                      ></i>
                    </span>
                    <span class="feature-list-text">Earn Passive Income</span>
                  </li>
                  <li class="feature-list-item">
                    <span class="feature-list-icon">
                      <i
                        aria-hidden="true"
                        class="fas fa-long-arrow-alt-right"
                      ></i>{" "}
                    </span>
                    <span class="feature-list-text">Expand Your Network</span>
                  </li>
                  <li class="feature-list-item">
                    <span class="feature-list-icon">
                      <i
                        aria-hidden="true"
                        class="fas fa-long-arrow-alt-right"
                      ></i>{" "}
                    </span>
                    <span class="feature-list-text">
                      Enjoy Exclusive Rewards:
                    </span>
                  </li>
                  <li class="feature-list-item">
                    <span class="feature-list-icon">
                      <i
                        aria-hidden="true"
                        class="fas fa-long-arrow-alt-right"
                      ></i>{" "}
                    </span>
                    <span class="feature-list-text">Support Your Friends</span>
                  </li>
                  <li class="feature-list-item">
                    <span class="feature-list-icon">
                      <i
                        aria-hidden="true"
                        class="fas fa-long-arrow-alt-right"
                      ></i>{" "}
                    </span>
                    <span class="feature-list-text">Contribute to Growth</span>
                  </li>
                  <li class="feature-list-item">
                    <span class="feature-list-icon">
                      <i
                        aria-hidden="true"
                        class="fas fa-long-arrow-alt-right"
                      ></i>
                    </span>
                    <span class="feature-list-text">
                      Be Part of Something Big
                    </span>
                  </li>
                </ul>
              </div>
              <div class="col-lg-4">
                <ul class="feature-list">
                  <li class="feature-list-item">
                    <span class="feature-list-icon">
                      <i
                        aria-hidden="true"
                        class="fas fa-long-arrow-alt-right"
                      ></i>
                    </span>
                    <span class="feature-list-text">Referral Rewards</span>
                  </li>
                  <li class="feature-list-item">
                    <span class="feature-list-icon">
                      <i
                        aria-hidden="true"
                        class="fas fa-long-arrow-alt-right"
                      ></i>
                    </span>
                    <span class="feature-list-text">Continuous Innovation</span>
                  </li>
                  <li class="feature-list-item">
                    <span class="feature-list-icon">
                      <i
                        aria-hidden="true"
                        class="fas fa-long-arrow-alt-right"
                      ></i>
                    </span>
                    <span class="feature-list-text">Community Engagement</span>
                  </li>
                  <li class="feature-list-item">
                    <span class="feature-list-icon">
                      <i
                        aria-hidden="true"
                        class="fas fa-long-arrow-alt-right"
                      ></i>
                    </span>
                    <span class="feature-list-text">
                      Instant Deposits and Withdrawals
                    </span>
                  </li>
                  <li class="feature-list-item">
                    <span class="feature-list-icon">
                      <i
                        aria-hidden="true"
                        class="fas fa-long-arrow-alt-right"
                      ></i>
                    </span>
                    <span class="feature-list-text">Global Accessibility</span>
                  </li>
                  <li class="feature-list-item">
                    <span class="feature-list-icon">
                      <i
                        aria-hidden="true"
                        class="fas fa-long-arrow-alt-right"
                      ></i>
                    </span>
                    <span class="feature-list-text">Regulatory Compliance</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="spacer">
          <div className="container">
            <div className="para_heading text-center">
              Why Refer to {webData?.website_title} Exchange?
            </div>
            <div className="row d-flex align-center justify-content-center mt-5">
              <div className="col-lg-4 col-md-4 col-12">
                <div className="card shadow-sm mb-4">
                  <div className="card-body padding_5">
                    <h4 className="refer_heading">Rewarding Friendship</h4>
                    <p className="text-lgray fs-12">
                      Share the joy of trading with your friends and earn
                      rewards together.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                <div className="card shadow-sm mb-4">
                  <div className="card-body padding_5">
                    <h4 className="refer_heading">Supportive Community</h4>
                    <p className="text-lgray fs-12">
                      Join a supportive community of traders and enthusiasts on
                      {webData?.website_title} Exchange.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                <div className="card shadow-sm mb-4">
                  <div className="card-body padding_5">
                    <h4 className="refer_heading">Secure and Reliable</h4>
                    <p className="text-lgray fs-12">
                      Trade with confidence on a secure and reliable platform
                      trusted by traders worldwide.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="spacer">
          <div className="container">
            <div className="row  align-items-center">
              <div className="col-lg-4 col-12">
                <div className=" pe-5">
                  <h2>What You Can Earn ?</h2>
                  <p className="text-lgrey">
                    Earn upto{" "}
                    <span className="text-highlight">
                      {" "}
                      {(webData?.level_income?.[1] ?? 0) +
                        (webData?.level_income?.[2] ?? 0) +
                        (webData?.level_income?.[3] ?? 0)}
                      % Commission{" "}
                    </span>{" "}
                    through 3 levels. <br></br>
                    {webData?.level_income?.[1] ?? 0}% on level one,{" "}
                    {webData?.level_income?.[2] ?? 0}% on level two,
                    {webData?.level_income?.[3] ?? 0}% on level three.
                  </p>{" "}
                </div>
                {/* <div className="col-lg-12">
                  <a href="#" className="action-btn me-3">
                    Start Earning Today!
                  </a>
                </div> */}
              </div>
              <div className="col-lg-8 col-12">
                <div className="row">
                  <div className="col-lg-4 col-6">
                    <div className="custom_card">
                      <div className="logo">
                        <div class="featured_icon">
                          <MdSecurity />
                        </div>
                      </div>
                      <div>
                        <div>
                          <h4>Tiered Commissions</h4>
                          <p>
                            Earn commissions up to {totalIncome}% across three
                            levels of referrals, rewarding you for your efforts
                            in growing our community.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-6">
                    <div className="custom_card">
                      <div className="logo">
                        <div class="featured_icon">
                          <LiaMoneyBillSolid />
                        </div>
                      </div>

                      <h4>Unlimited Earning Potential</h4>
                      <p>
                        There's no cap on how much you can earn. The more you
                        refer and the more your referrals trade, the more you
                        earn!
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-6">
                    <div className="custom_card">
                      <div className="logo">
                        <div class="featured_icon">
                          <FaChartLine />
                        </div>
                      </div>
                      <div>
                        <h4>Be Your Own Boss</h4>
                        <p>
                          Take control of your financial destiny and become your
                          own boss with our flexible and rewarding referral
                          program.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-8">
          <div className="row spacer">
         
          </div>
          </div> */}
            </div>
          </div>
        </div>

        <div className="container spacer">
          <div className="col-lg-12 text-center">
            <h3 className=""> Help Center </h3>
          </div>
          <div className="col-lg-12">
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    How do I participate in the {webData?.website_title}{" "}
                    Exchange referral program?
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    To participate in the {webData?.website_title} Exchange
                    referral program, simply sign up for an account on our
                    platform. Once logged in, you can access your unique
                    referral link from your account dashboard.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    Can I refer multiple friends to {webData?.website_title}{" "}
                    Exchange?
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Absolutely! There's no limit to the number of friends you
                    can refer to {webData?.website_title} Exchange. The more
                    friends you refer, the more rewards you can earn
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    What rewards can I earn through the referral program?
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Through the referral program, you can earn commissions,
                    bonuses, and exclusive perks. Commissions are earned based
                    on the trading activity of your referred friends, while
                    bonuses and perks are awarded as you reach referral
                    milestones.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingfour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapsefour"
                    aria-expanded="false"
                    aria-controls="flush-collapsefour"
                  >
                    How are referral commissions calculated?
                  </button>
                </h2>
                <div
                  id="flush-collapsefour"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingfour"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Referral commissions are calculated as a percentage of the
                    trading fees generated by your referred friends. The exact
                    percentage may vary depending on the trading activity and
                    referral tier.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingfive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapsefive"
                    aria-expanded="false"
                    aria-controls="flush-collapsefive"
                  >
                    When will I receive my referral rewards?
                  </button>
                </h2>
                <div
                  id="flush-collapsefive"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingfive"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Referral rewards are typically credited to your{" "}
                    {webData?.website_title}
                    Exchange account in real-time as your referred friends
                    complete trades. You can view your referral earnings in your
                    account dashboard.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingsix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapsesix"
                    aria-expanded="false"
                    aria-controls="flush-collapsesix"
                  >
                    Is there a limit to the number of referral levels?
                  </button>
                </h2>
                <div
                  id="flush-collapsesix"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingsix"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Currently, the {webData?.website_title} Exchange referral
                    program offers rewards across three levels of referrals.
                    This means you can earn commissions not only from your
                    direct referrals but also from the referrals of your
                    referred friends.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingseven">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseseven"
                    aria-expanded="false"
                    aria-controls="flush-collapseseven"
                  >
                    How do I track the status of my referrals?
                  </button>
                </h2>
                <div
                  id="flush-collapseseven"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingseven"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Most referral programs provide a dashboard or section within
                    your account where you can track the status of your
                    referrals. This includes information on who has signed up or
                    made a purchase using your referral link, as well as the
                    rewards you've earned. If you have trouble finding this
                    information, reach out to customer support for assistance.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingeigth">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseeight"
                    aria-expanded="false"
                    aria-controls="flush-collapseeight"
                  >
                    Is there an expiration date for my referral credits or
                    rewards?
                  </button>
                </h2>
                <div
                  id="flush-collapseeight"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingeight"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Referral program terms may include expiration dates for
                    earned rewards. It's essential to check the terms and
                    conditions of the program to understand any time limitations
                    on the usability of your referral credits or rewards.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingnine">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapsenine"
                    aria-expanded="false"
                    aria-controls="flush-collapsenine"
                  >
                    Can I participate in a referral program as a new customer?
                  </button>
                </h2>
                <div
                  id="flush-collapsenine"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingnine"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Yes, referral programs are often open to both existing
                    customers (referrers) and new customers (referees). New
                    customers can usually sign up through a referral link
                    provided by an existing customer to access special offers or
                    discounts.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingten">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseten"
                    aria-expanded="false"
                    aria-controls="flush-collapseten"
                  >
                    What should I do if I have issues with the referral program
                    or rewards?
                  </button>
                </h2>
                <div
                  id="flush-collapseten"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingten"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    If you encounter any issues with the referral program or
                    have questions about your rewards, contact the customer
                    support team of the company running the program. They should
                    be able to assist you with any concerns or inquiries you may
                    have.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CalltoAction />
      <FooterNav />
      <Footer />
    </>
  );
}
