import React from "react";
import { CiMoneyCheck1 } from "react-icons/ci";
import { HiOutlineGift } from "react-icons/hi";
import { IoShareSocialOutline } from "react-icons/io5";
import { SlUser } from "react-icons/sl";
import { TbUserShield } from "react-icons/tb";
import { useSelector } from "react-redux";
const Process = () => {
    const { webData } = useSelector((state) => state.websiteDBReducer);
  return (
    <>
      <div className="spacer">
        <div className="container">
          <h2 className="fw-bold text-center">{webData?.website_title} Beginner's Guide</h2>
          <h3 className="text-center fs-5">How do I trade on this platform?</h3>
          <div className="row d-flex align-center justify-content-center mt-5">
            <div className="col-lg-3 col-md-6 col-12 padding_0">
              <div className="card bg-transparent border-0 text-center">
                <div className="card-body padding_5 card-align">
                  <div>
                  <div class="text-center mb-3">
                    <div class="vtimeline_no active"> 1</div>
                    <div class="vtimeline_right"></div>
                  </div>
                  <h5 className="refer_heading">
                    <SlUser className="fs-4" /> Sign up{" "}
                  </h5>
                  <p className="text-lgray">
                    Register for an account on {webData?.website_title} Exchange or log in if
                    you're already a member.
                  </p>
                  </div>
                  <div className="custom_badge bg-soft-gray overflow-btn">
                  Enter email and password
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 padding_0">
              <div className="card bg-transparent border-0 text-center">
                <div className="card-body padding_5 card-align">
                  <div>
                  <div class="text-center mb-3">
                    <div class="vtimeline_left"></div>
                    <div class="vtimeline_no "> 2</div>
                    <div class="vtimeline_right"></div>
                  </div>

                  <h5 className="refer_heading">
                    {" "}
                    <TbUserShield className="fs-4" /> Complete KYC
                  </h5>
                  <p className="text-lgray">
                  To ensure the security and legitimacy of our services, we require you to complete the Know Your Customer (KYC) process
                  </p>
                  </div>
                  <div className="custom_badge bg-soft-gray overflow-btn"> Required information and documents </div> 
                             </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 padding_0">
              <div className="card bg-transparent border-0 text-center">
                <div className="card-body padding_5 card-align">
                  <div>
                  <div class="text-center mb-3">
                    <div class="vtimeline_left"></div>
                    <div class="vtimeline_no "> 3</div>
                    <div class="vtimeline_right"></div>
                  </div>

                  <h5 className="refer_heading">
                    {" "}
                    <HiOutlineGift className="fs-4" /> Get Reward
                  </h5>
                  <p className="text-lgray">
                    As your referrals begin trading on {webData?.website_title} Exchange, you'll
                    reward commissions on every trade they make.
                  </p>
                  </div>
                  <div className="custom_badge bg-soft-gray overflow-btn">
                  ₹1000 worth of {webData?.referral_coin} + 100000 SHIB
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 padding_0">
              <div className="card bg-transparent border-0 text-center">
                <div className="card-body padding_5 card-align">
                  <div>
                  <div class="text-center mb-3">
                    <div class="vtimeline_left"></div>
                    <div class="vtimeline_no "> 4</div>
                  
                  </div>

                  <h5 className="refer_heading">
                    {" "}
                    <IoShareSocialOutline  className="fs-4" /> Start Referring
                  </h5>
                  <p className="text-lgray">
                  Share your referral link via social media, email, or any
                    other platform where your audience resides.
                  </p>
                  </div>
                  <div className="custom_badge bg-soft-gray overflow-btn">
                  ₹100 worth of {webData?.referral_coin} on each referral
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Process;
