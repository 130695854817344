import React, { useEffect, useState } from "react";
import $ from "jquery";
import { FaArrowUp, FaLongArrowAltUp } from "react-icons/fa";
import { GoArrowDown, GoArrowUp } from "react-icons/go";
import CalltoAction from "./CalltoAction";
import FooterNav from "./FooterNav";
import { NotificationManager } from "react-notifications";
import Footer from "./Footer";
import Header from "./Header";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";
import { N_Validate } from "../redux/helpers/api_functions_new";
import { Helmet } from "react-helmet";
import OTPInput from "react-otp-input";
import { LiaMoneyBillSolid } from "react-icons/lia";
import { MdSecurity } from "react-icons/md";
import { FaChartLine, FaCoins, FaTelegram } from "react-icons/fa";

const Validator = (props) => {
  const { user, profile, isLoggedIn } = useSelector(
    (state) => state.AuthReducer
  );
  const { webData } = useSelector((state) => state.websiteDBReducer);
  const { wallet, coins } = useSelector((state) => state.coinDBReducer);
  const token = user?.params ? user.params.token : user.token;
  const formatDate = (date) => {
    return date.toLocaleDateString("en-GB"); // Formats as DD/MM/YYYY
  };
  const [loading, setLoading] = useState(false);
  const [claimloading, setcliamLoading] = useState(false);
  const [errorHarvestMessage, setHarvestMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [amount, setAmount] = useState();
  const [endAmount, setendAmount] = useState(0);
  const [interest, setInterrest] = useState(0);
  const [interest_rate, setInterrestRate] = useState(0);
  const [interestAmount, setInterrestAmount] = useState(0);
  const [subscriptionDate, setSubscriptionDate] = useState(
    formatDate(new Date())
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [action, setAction] = useState("open");
  const [endSubscriptionDate, setEndSubscriptionDate] = useState("DD/MM/YYYY");
  const [agree, setAgree] = useState(false);
  const [validate_coin, setValidate] = useState(null);
  const [activeTradeIndex, setActiveTradeIndex] = useState({});
  const [controller, setController] = useState(null);
  const [bal, setBalance] = useState(0);
  const [notp, setNOtp] = useState(null);
  const [isNOTPSent, setIsNOTPSent] = useState(false);
  const [validateHistory, setValidateHistory] = useState([]);
  const [validatorInc, setValidatorInc] = useState(0);
  const totalIncome =
    (webData?.validator_income?.[1] ?? 0) +
    (webData?.validator_income?.[2] ?? 0) +
    (webData?.validator_income?.[3] ?? 0);

  const [ry, setRy] = useState({
    total_ry: 0,
    symbol: "",
  });

  const captureFund = (action) => {
    if (controller) {
      controller.abort();
    }

    // Create a new controller for the new request
    const newController = new AbortController();
    setController(newController);
    N_Validate(token, action, newController).then((res) => {
      if (action !== "list") {
        if (!res.error) {
          NotificationManager.success(res.message);
        } else {
          NotificationManager.error(res.message);
        }
        document.location.reload();
      }
      setValidate(res.validator);
    });
  };

  useEffect(() => {
    let oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    setEndSubscriptionDate(formatDate(oneYearFromNow));
    captureFund("list");
  }, []);

  useEffect(() => {
    let coindata = wallet && wallet?.find((item) => item.symbol == "DTBX");
    let bal = coindata?.balance - coindata?.locked;
    setBalance(bal);
  }, [wallet]);

  return (
    <>
      <Header />
      <div className="application">
        <Helmet>
          <meta
            name="description"
            content="Explore the market for seamless and secure crypto trading. Dive
into the world of Crypto Trading and elevate your trading experience. "
          />
          <title>Unlock {`${webData?.website_title}`} Validator Rewards</title>
        </Helmet>
      </div>
      <div className="page-content">
        <div className="container market_trend spacer-top">
          <div class="market-text">
            <div className="staking-plan-flex">
              <div className="staking-plan">
                <h2>Validator Plans</h2>
                <p>Remaining Validator {webData?.validator}</p>
              </div>
            </div>
            <div class="spacer-sm staking_wrapper">
              <div class="ana-tb-list is-separate">
                <div class="ana-tb-item ana-tb-head">
                  <div class="ana-tb-col w3 ">Coin/Token</div>
                  <div class="ana-tb-col w4 ">
                    <div class="ana-tab-content">
                      <div className="ana_label">Est. APR</div>
                    </div>
                  </div>
                  <div class="ana-tb-col w5">
                    <div class="ana-tab-content">
                      <div className="ana_label">Duration</div>
                    </div>
                  </div>
                  <div class="ana-tb-col w3">
                    <div class="ana-tab-content">
                      <div className="ana_label">Amount </div>
                    </div>
                  </div>

                  <div class="ana-tb-col w2">
                    <div class="ana-tab-content">
                      <div className="ana_label">Action</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion accordion-flush" id="satkingAccordion">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <div class="ana-tb-item">
                      <div class="ana-tb-col w3">
                        <div className="d-flex align-items-center gap-2">
                          <div className="width35">
                            <img
                              src="https://new.dtbx.exchange/logo192.png"
                              alt="coin symbole"
                              className="market_coin_img"
                            />
                          </div>
                          <div className="product_name">
                            <div className="fw-bold text-dark">DTBX</div>
                            <div className="text-muted fs-12">DotBlox</div>
                          </div>
                        </div>
                      </div>
                      <div class="ana-tb-col w4 p20 ">
                        <div class="ana-tab-content">100%</div>
                      </div>
                      <div class="ana-tb-col w5 p19">
                        <div class="ana-tab-content">
                          <div className="d-flex gap-2 align-items-center">
                            <div class="">
                              <div class="d-flex gap-1 flex-wrap">
                                <div
                                  className={`btn btn-sm duration-btn cursor-pointer active`}
                                >
                                  365 days
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="ana-tb-col w3 p19">
                        <div class="ana-tab-content">100000 DTBX</div>
                      </div>

                      <div class="ana-tb-col w2">
                        <div class="ana-tab-content">
                          {isLoggedIn ? (
                            <button
                              class="btn-valid "
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#validator"
                              aria-expanded="true"
                              aria-controls="validator"
                            >
                              Enroll Now
                            </button>
                          ) : (
                            <button
                              class="action-btn staking-btn"
                              type="button"
                              onClick={() => {
                                props.history?.replace("/login");
                              }}
                            >
                              Enroll Now
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </h2>
                  <div
                    id="validator"
                    class="accordion-collapse collapse"
                    data-bs-parent="#satkingAccordion"
                  >
                    <div class="accordion-body staking-accordion-body border rounded">
                      <div class="row cstm_form">
                        <div className="col-lg-6">
                          <div className="p-4">
                            <p className="text-danger mb-0 fs-14">
                              {" "}
                              {errorMessage}
                            </p>
                            <div className="form-group mb-4">
                              <label class="small text-muted">
                                Available Balance
                              </label>
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  readOnly
                                  value={bal}
                                />
                              </div>
                            </div>

                            {validate_coin ? (
                              <div className="d-flex justify-content-between">
                                <div> Total : {validate_coin.amount} DTBX </div>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="p-4">
                            <div class="p-4 shadow-4 rounded-3 bg-gredient-primary">
                              <h2 className="fs-16 fw-bold">Summary</h2>

                              <div className="d-flex justify-content-between">
                                <div>
                                  <div className="hero_heading">
                                    Start Volume
                                  </div>
                                  <div>100000</div>
                                </div>
                                <div>
                                  <div className="hero_heading">End Volume</div>
                                  <div>200000</div>
                                </div>
                                <div>
                                  <div className="hero_heading">Interest</div>
                                  <div>100000</div>
                                </div>
                              </div>
                            </div>

                            <div className="info-wrap">
                              <div class="infoLists">
                                <div class="listInfo">
                                  <span class="listInfo-title">
                                    Subscription Date:
                                  </span>
                                  <span class="listInfo-text">
                                    {subscriptionDate}
                                  </span>
                                </div>
                                <div class="listInfo">
                                  <span class="listInfo-title">
                                    End Subscription Date:
                                  </span>
                                  <span class="listInfo-text">
                                    {endSubscriptionDate}
                                  </span>
                                </div>
                                <div class="listInfo">
                                  <span class="listInfo-title">
                                    Credit In:{" "}
                                  </span>
                                  <span class="listInfo-text">Maturity</span>
                                </div>
                                <div class="listInfo">
                                  <span class="listInfo-title">
                                    Withdrawal Date:
                                  </span>
                                  <span class="listInfo-text">Maturity</span>
                                </div>
                              </div>
                            </div>
                            <div className="form-check">
                              <input
                                class="form-check-input main_terms"
                                type="checkbox"
                                checked={agree}
                                onChange={(e) => setAgree(e.target.checked)}
                                id="termsChecked"
                              />
                              <label
                                class="form-check-label fs-12 "
                                for="termsChecked"
                              >
                                I agree with{" "}
                                <Link to="/terms_&_conditions">
                                  terms & conditions
                                </Link>
                              </label>
                            </div>

                            <div class="d-grid mt-4">
                              {loading ? (
                                <div
                                  class="spinner-border text-success align-center"
                                  role="status"
                                >
                                  <span class="sr-only">Loading...</span>
                                </div>
                              ) : (
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    setLoading(true);
                                    if (controller) {
                                      controller.abort();
                                    }

                                    // Create a new controller for the new request
                                    const newController = new AbortController();
                                    setController(newController);
                                    let action = "set";
                                    N_Validate(
                                      token,
                                      action,
                                      newController
                                    ).then((res) => {
                                      setLoading(false);
                                      if (res.status == 200) {
                                        NotificationManager.success(
                                          res.message
                                        );
                                      } else {
                                        NotificationManager.error(res.message);
                                      }
                                      setErrorMessage(res.message);
                                      setTimeout(() => {
                                        setErrorMessage("");
                                      }, 1000);
                                    });
                                  }}
                                >
                                  Submit
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nav-item nav-link fs-14 " style={{ height: "35px" }}>
            Validator History
          </div>

          <div className="tab-pane fade show active">
            <div className="overflow-auto mt-3">
              <div className="table_scroll_div">
                <table className="table global_table">
                  <thead>
                    <tr>
                      <th>Coin/Token</th>
                      <th>Est.Apr</th>
                      <th>Duration</th>
                      <th>Amount</th>
                      <th>Interest</th>
                      <th>Creation Date</th>
                      <th>Matuirity Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {validate_coin?.length > 0 ? (
                      validate_coin?.map((item) => {
                        return (
                          <tr>
                            <td className="border-bottom-0 text-muted">
                              <p>{item.wallet_type}</p>
                            </td>
                            <td className="border-bottom-0 text-muted">
                              <p>{item?.percent}%</p>
                            </td>
                            <td className="border-bottom-0 text-muted">
                              <p>365 days</p>
                            </td>
                            <td className="border-bottom-0 text-muted">
                              <p>{item?.amount}</p>
                            </td>
                            <td className="border-bottom-0 text-muted">
                              <p>{(item?.amount * item.percent) / 100}</p>
                            </td>
                            <td className="border-bottom-0 text-muted">
                              <p>
                                {new Date(item?.createdAt).toLocaleString()}
                              </p>
                            </td>
                            <td className="border-bottom-0 text-muted">
                              <p>
                                {new Date(item?.maturity_date).toLocaleString()}
                              </p>
                            </td>
                            <td className="border-bottom-0 text-muted">
                              <p>
                                {item?.stake_completed ? (
                                  "Success"
                                ) : (
                                  <button
                                    class="btn btn-success"
                                    onClick={() => {
                                      captureFund(item?._id);
                                    }}
                                  >
                                    Claim
                                  </button>
                                )}
                              </p>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan={9}
                          className="text-center border-bottom-0 text-muted"
                        >
                          <img
                            src="/img/no-data.png"
                            className="no-data-found"
                          />
                          <p>No records</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="spacer">
            <div className="container">
              <div className="row  align-items-center">
                <div className="col-lg-4 col-12">
                  <div className=" pe-5">
                    <h2>What You Can Earn ?</h2>
                    <p className="text-lgrey">
                      Earn upto{" "}
                      <span className="text-highlight">
                        {" "}
                        {(webData?.validator_income?.[1] ?? 0) +
                          (webData?.validator_income?.[2] ?? 0) +
                          (webData?.validator_income?.[3] ?? 0)}
                        % Commission{" "}
                      </span>{" "}
                      through 3 levels. <br></br>
                      {webData?.validator_income?.[1] ?? 0}% on level one,{" "}
                      {webData?.validator_income?.[2] ?? 0}% on level two,
                      {webData?.validator_income?.[3] ?? 0}% on level three.
                    </p>{" "}
                  </div>
                  {/* <div className="col-lg-12">
                  <a href="#" className="action-btn me-3">
                    Start Earning Today!
                  </a>
                </div> */}
                </div>
                <div className="col-lg-8 col-12">
                  <div className="row">
                    <div className="col-lg-4 col-6">
                      <div className="custom_card">
                        <div className="logo">
                          <div class="featured_icon">
                            <MdSecurity />
                          </div>
                        </div>
                        <div>
                          <div>
                            <h4>Tiered Commissions</h4>
                            <p>
                              Earn commissions up to {totalIncome}% across three
                              levels of referrals, rewarding you for your
                              efforts in growing our community.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-6">
                      <div className="custom_card">
                        <div className="logo">
                          <div class="featured_icon">
                            <LiaMoneyBillSolid />
                          </div>
                        </div>

                        <h4>Unlimited Earning Potential</h4>
                        <p>
                          There's no cap on how much you can earn. The more you
                          refer and the more your referrals trade, the more you
                          earn!
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-6">
                      <div className="custom_card">
                        <div className="logo">
                          <div class="featured_icon">
                            <FaChartLine />
                          </div>
                        </div>
                        <div>
                          <h4>Be Your Own Boss</h4>
                          <p>
                            Take control of your financial destiny and become
                            your own boss with our flexible and rewarding
                            referral program.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-8">
          <div className="row spacer">
         
          </div>
          </div> */}
              </div>
            </div>
          </div>
          <div className="spacer">
            <div className="container">
              <div className="para_heading">Frequently Asked Questions</div>
              <p className="text-left text-lgray">
                Frequently asked questions (FAQs) for instant trading
              </p>

              <div className="row mt-5">
                <div class="accordion accordion-flush" id="faqAccordion">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Become a Validator on the DTBX Blockchain: Secure the
                        Network, Earn Rewards{" "}
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        <p>
                          The DTBX blockchain invites participants to join its
                          network as validators, contributing to
                          decentralization and security while earning
                          substantial rewards. By locking DTBX coins, validators
                          play a critical role in maintaining consensus and
                          ensuring the integrity of the blockchain.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Validator Requirements{" "}
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        <span className="fw-bold">Lock 100,000 DTBX:</span>{" "}
                        Validators must stake at least 100,000 DTBX coins.
                      </div>
                    </div>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        <span className="fw-bold">Commitment Period:</span>{" "}
                        Coins are locked for 365 days (1 year), ensuring
                        long-term network stability.{" "}
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Rewards{" "}
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        <span className="fw-bold">Fixed Reward:</span> Earn
                        1,00,000 DTBX (a 100% annual yield) upon completing the
                        365-day lock period.
                      </div>
                    </div>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        <span className="fw-bold">Guaranteed Payout:</span>{" "}
                        Rewards are distributed automatically once the lock-up
                        period ends, provided validation duties are fulfilled.
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse4"
                        aria-expanded="false"
                        aria-controls="collapse4"
                      >
                        Key Features{" "}
                      </button>
                    </h2>
                    <div
                      id="collapse4"
                      class="accordion-collapse collapse"
                      aria-labelledby="heading5"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        <span className="fw-bold">High Yield:</span> A 100%
                        annual return on staked Coins, significantly
                        outperforming traditional investment avenues.
                      </div>
                    </div>
                    <div
                      id="collapse4"
                      class="accordion-collapse collapse"
                      aria-labelledby="heading5"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        <span className="fw-bold">Network Governance:</span>{" "}
                        Validators gain voting rights to influence protocol
                        upgrades and governance proposals.
                      </div>
                    </div>
                    <div
                      id="collapse4"
                      class="accordion-collapse collapse"
                      aria-labelledby="heading5"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        <span className="fw-bold">Security Focus:</span>
                        Validators are incentivized to act honestly, as
                        malicious behaviour risks penalties (e.g., slashing
                        conditions may apply).
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse5"
                        aria-expanded="false"
                        aria-controls="collapse5"
                      >
                        How to Participate{" "}
                      </button>
                    </h2>
                    <div
                      id="collapse5"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        <span className="fw-bold">Acquire DTBX Coins:</span>{" "}
                        Purchase or transfer 100,000 DTBX to your wallet.
                      </div>
                    </div>
                    <div
                      id="collapse5"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        <span className="fw-bold">Delegate & Lock:</span> Use
                        the DTBX staking portal to lock your Coins for 365 days.{" "}
                      </div>
                    </div>
                    <div
                      id="collapse5"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        <span className="fw-bold">Run a Node:</span> Maintain an
                        active, high-uptime node to validate transactions
                        (technical setup guides provided).
                      </div>
                    </div>
                    <div
                      id="collapse5"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        <span className="fw-bold">Earn Rewards:</span> Receive
                        1,00,000 DTBX after the lock period ends, plus potential
                        additional incentives for exceptional participation.{" "}
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse6"
                        aria-expanded="false"
                        aria-controls="collapse6"
                      >
                        Benefits of Validating{" "}
                      </button>
                    </h2>
                    <div
                      id="collapse6"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        <span className="fw-bold">Passive Income:</span> Earn
                        rewards while supporting the blockchain’s growth.
                      </div>
                    </div>
                    <div
                      id="collapse6"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        <span className="fw-bold">
                          Decentralized Leadership:
                        </span>{" "}
                        Play a direct role in shaping DTBX’s future.
                      </div>
                    </div>
                    <div
                      id="collapse6"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        <span className="fw-bold">Priority Access:</span> Gain
                        early insights into network upgrades and partnerships.
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse7"
                        aria-expanded="false"
                        aria-controls="collapse7"
                      >
                        Risks to Consider{" "}
                      </button>
                    </h2>
                    <div
                      id="collapse7"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        <span className="fw-bold">Liquidity Lock:</span> Coins
                        cannot be withdrawn or traded during the 365 days.
                      </div>
                    </div>
                    <div
                      id="collapse7"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#faqAccordion"
                    >
                      <div class="accordion-body">
                        <span className="fw-bold">Slashing:</span> Penalties may
                        apply for downtime or malicious activity (details in
                        validator agreement).
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="para_heading mt-5">Note:</div>
              <p className="text-left text-lgray">
                <span className="fw-bold">
                  Join the DTBX Validator Community Today!
                </span>{" "}
                By staking 100,000 DTBX, you become a cornerstone of the
                blockchain’s security and efficiency. Seize this opportunity to
                earn significant rewards while driving the future of
                decentralized technology.
              </p>
              <p className="text-left text-lgray">
                <span className="fw-bold">Start Validating Now:</span> Visit
                <a
                  href="https://new.dtbx.exchange"
                  className="fw-bold link-colr"
                >
                  {" "}
                  www.dtbx.exchange
                </a>{" "}
                to lock your coins and begin your journey as a DTBX validator.{" "}
              </p>
              <p className="text-left text-lgray">
                <span className="fw-bold">
                  Terms and conditions apply. Review the validator agreement for
                  slashing rules, node requirements, and reward distribution
                  details.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <CalltoAction />
      <FooterNav />
      <Footer />
    </>
  );
};

export default Validator;
