import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";

import { styled } from "@mui/material/styles";
import { getUserBalance, getUserOrder } from "../redux/actions/coinDBAction";
import {
  N_createBuyOffer,
  N_createMarkeOffer,
  N_createSellOffer,
  N_executeOrder,
} from "../redux/helpers/api_functions_new";

import { mul, round } from "../redux/helpers/Math";
import { getRound } from "../redux/helpers/helper_functions";
import { Link } from "react-router-dom";
import Zoom from "@mui/material/Zoom";
import { IoIosArrowDropright } from "react-icons/io";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

export default function BuyNSell(props) {
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 200,
    },
  }));

  const { isLoggedIn, user } = useSelector((state) => state.AuthReducer);
  const {
    coins,
    wallet,
    paired_curency_price,
    buy_order_book,
    sell_order_book,
    buymarket,
    sellmarket,
    vhl_data,
    currency_type,
    campare_currency,
    buyorder,
    sellorder
  } = useSelector((state) => state.coinDBReducer);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [bloading, setbLoading] = useState(false);
  const [atPrice, setATPrice] = useState(0);
  const [atsPrice, setATSPrice] = useState(0);
  const [stopprice, setStopPrice] = useState(0);
  const [cprice, setCprice] = useState(0);
  const [spreadvalue, setSpread] = useState(4);
  const [activeTab, setActiveTab] = useState(0);
  const [amount, setAmount] = useState(0);
  const [asmount, setSAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [stotal, setSTotal] = useState(0);
  const [stopLimit, setStoplimit] = useState(0);
  const [wallet_details, setWalletDetails] = useState([]);
  const [buybalance, setbuybalance] = useState(0);
  const [sellbalance, setsellbalance] = useState(0);
  const [makerFee, setMakerFee] = useState();
  const [TakerFee, setTakerFee] = useState();
  const [buycontroller, setBuyController] = useState(null);
  const [sellcontroller, setSellController] = useState(null);
  const [selectedOption, setSelectedOption] = useState("limit");
  const [buyorderpercent, setBuyOrderPercent] = useState(0);
  const [sellorderpercent, setSellOrderPercent] = useState(0);
  const coin = props?.match?.params?.id
    ? props.match.params.id.split("-")
    : currency_type + "-" + campare_currency.split("-");
  const coinname = coin[0] + coin[1];
  const [price, setprice] = useState(0);
  let time = 0;
  useEffect(() => {
    // console.log(amount * atsPrice,stotal)
    setSTotal(round(asmount * atPrice));
  }, [atPrice, amount, total]);

  useEffect(() => {
    // console.log(asmount * atsPrice,stotal)
    setSTotal(round(asmount * atsPrice));
  }, [atsPrice, asmount, stotal]);

  function setCurrentBuySellTokenBalance() {
    wallet_details.map((item, i) => {
      if (item.symbol === coin[1].toUpperCase()) {
        setbuybalance(getRound(item.avl_balance));
      } else if (item.symbol === coin[0].toUpperCase()) {
        setsellbalance(getRound(item.avl_balance));
      }
    });
  }
  // console.log(stateVariable);

  function getCurrentBuySellTokenBalance() {
    let final_data =
      wallet &&
      Object.keys(wallet).map((res, i) => {
        return {
          id: wallet[res]?.id,
          icon: wallet[res]?.icon,
          symbol: wallet[res]?.symbol.toUpperCase(),
          name: wallet[res]?.name,
          status: wallet[res]?.status,
          withdral_fee: wallet[res]?.withdrawal_fee,
          locked: getRound(wallet[res]?.locked),
          address: wallet[res]?.wallet_address,
          balance: getRound(wallet[res]?.balance),
          avl_balance: getRound(wallet[res]?.balance - wallet[res]?.locked),
        };
      });
    setWalletDetails(final_data);
    // console.log("fdata: ", final_data);
    setTimeout(() => setLoading(false), 800);
  }

  useEffect(() => {
    setCurrentBuySellTokenBalance();
  }, [coin, wallet_details]);

  useEffect(() => {
    const data = Object.values(coins).find((d) => {
      if (d.symbol === coin[0].toUpperCase()) {
        return d;
      }
    });
    let spred =
      coin[1].toUpperCase() == "BTC"
        ? data?.spread_btc
        : coin[1].toUpperCase() == "USDT"
        ? data?.spread_usdt
        : coin[1].toUpperCase() == "INR"
        ? data?.spread_inr
        : data?.spread_vrx;
    // Get the data corresponding to the matching key
    setSpread(spred);
    const matchingData = vhl_data[props.match.params.id];
    if (matchingData) {
      // console.log('currentPrice', price)
      setprice(matchingData.raw_price?.toFixed(spred));
    } else {
      setprice(
        (
          Number(data?.current_price) /
          Number(
            paired_curency_price
              ? paired_curency_price[coin[1].toUpperCase()]
                ? paired_curency_price[coin[1].toUpperCase()]
                : 1
              : 1
          )
        )?.toFixed(spred)
      );
    }
    setMakerFee(data?.maker_fees);
    setTakerFee(data?.taker_fees);
  }, [coins, vhl_data, props?.match?.params?.id, paired_curency_price]);

  useEffect(() => {
    setATPrice(Number(price)?.toFixed(spreadvalue));
    setATSPrice(Number(price)?.toFixed(spreadvalue));
    let tarr = buy_order_book[coinname]?.sort(
      (a, b) => b.raw_price - a.raw_price
    );

    let t1arr = sell_order_book[coinname]?.sort(
      (a, b) => a.raw_price - b.raw_price
    );

    if (t1arr?.length > 0) {
      let raw_price = t1arr[0]?.raw_price;
      setATPrice(Number(raw_price)?.toFixed(spreadvalue));
    }
    if (tarr?.length > 0) {
      let raw_price = tarr[0]?.raw_price;
      setATSPrice(Number(raw_price)?.toFixed(spreadvalue));
    }
    setCprice(Number(price)?.toFixed(spreadvalue));
  }, [buy_order_book, sell_order_book, coinname, wallet, price]);

  // useEffect(() => {

  //   const data = Object.values(coins).find((d) => {
  //     if (d.symbol === coin[0].toUpperCase()) {
  //       return d;
  //     }
  //   });
  //   if (price && time == 0) {
  //     setATPrice(Number(price)+Number(data?.filter_buy_price));
  //     setATSPrice(Number(price)+Number(data?.filter_sell_price));
  //     setCprice(price);
  //     time = 1;
  //   }
  // }, [price, wallet]);

  useEffect(() => {
    if (isLoggedIn && user?.params ? user.params.token : user.token) {
      getCurrentBuySellTokenBalance();
    }
  }, [wallet]);
  useEffect(() => {
    const mprice = buymarket.marketprice;
    const mvolume = round(buymarket.marketvolume);
    const totalinr = mul(mvolume, mprice);
    setATSPrice(Number(mprice)?.toFixed(spreadvalue));
    setSAmount(mvolume);
    setSTotal(totalinr);
    setActiveTab(buymarket.active);
  }, [buymarket]);

  useEffect(() => {
    const mprice = sellmarket.marketprice;
    const mvolume = round(sellmarket.marketvolume);
    const totalinr = mul(mvolume, mprice);
    setATPrice(Number(mprice)?.toFixed(spreadvalue));
    setAmount(mvolume);
    setTotal(totalinr);
    setActiveTab(sellmarket.active);
  }, [sellmarket]);
  useEffect(() => {
    const buyVolume = buyorder.reduce((sum, order) => sum + order.volume, 0);
    const sellVolume = sellorder.reduce((sum, order) => sum + order.volume, 0);
    const totalVolume = buyVolume + sellVolume;
  
    if (totalVolume === 0) {
      setBuyOrderPercent(0);
      setSellOrderPercent(0);
    } else if (buyVolume === 0) {
      setBuyOrderPercent(0);
      setSellOrderPercent(100);
    } else if (sellVolume === 0) {
      setBuyOrderPercent(100);
      setSellOrderPercent(0);
    } else {
      setBuyOrderPercent(Number(((buyVolume / totalVolume) * 100).toFixed(2)));
      setSellOrderPercent(Number(((sellVolume / totalVolume) * 100).toFixed(2)));
    }
  }, [buyorder, sellorder]);

  function buyCoin(atPrice, amount, c, cp) {
    setbLoading(true);
    if (buycontroller) {
      buycontroller.abort();
    }

    // Create a new controller for the new request
    const newController = new AbortController();
    setBuyController(newController);
    if (selectedOption == "market") {
      N_createMarkeOffer(
        atPrice,
        stopprice,
        amount,
        c,
        cp,
        user?.params ? user.params.token : user.token,
        cprice,
        newController,
        "buy",
        selectedOption
      )
        .then((d) => {
          if (d.status == 200) {
            NotificationManager.success(d.message);
            dispatch(
              getUserBalance(user?.params ? user.params.token : user.token)
            );
            dispatch(
              getUserOrder(user?.params ? user.params.token : user.token, c, cp)
            );
            setAmount(0);
            setTotal(0);
            getCurrentBuySellTokenBalance();
          } else {
            NotificationManager.error(d.message);
          }
          setbLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      N_createBuyOffer(
        atPrice,
        stopprice,
        amount,
        c,
        cp,
        user?.params ? user.params.token : user.token,
        cprice,
        newController,
        "buy",
        selectedOption
      )
        .then((d) => {
          if (d.status == 200) {
            NotificationManager.success(d.message);
            getCurrentBuySellTokenBalance();
            if (selectedOption == "limit") {
              dispatch(
                getUserBalance(user?.params ? user.params.token : user.token)
              );
              N_executeOrder(
                d.result.order_id,
                user?.params ? user.params.token : user.token,
                d.result.type
              ).then((d) => {
                if (d.status == 200) {
                  getCurrentBuySellTokenBalance();
                  dispatch(
                    getUserBalance(
                      user?.params ? user.params.token : user.token
                    )
                  );
                  dispatch(
                    getUserOrder(
                      user?.params ? user.params.token : user.token,
                      c,
                      cp
                    )
                  );
                  setprice(0);
                  setAmount(0);
                  setTotal(0);
                }
              });
            }
          } else if (d.status == 400) {
            getCurrentBuySellTokenBalance();
            dispatch(
              getUserBalance(user?.params ? user.params.token : user.token)
            );
            dispatch(
              getUserOrder(user?.params ? user.params.token : user.token, c, cp)
            );
            NotificationManager.success(d.message);
          }
          setbLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  function sellCoin(atPrice, amount, c, cp) {
    setLoading(true);
    if (sellcontroller) {
      sellcontroller.abort();
    }

    // Create a new controller for the new request
    const newController = new AbortController();
    setSellController(newController);
    if (selectedOption == "market") {
      N_createMarkeOffer(
        atPrice,
        stopprice,
        amount,
        c,
        cp,
        user?.params ? user.params.token : user.token,
        cprice,
        newController,
        "sell",
        selectedOption
      )
        .then((d) => {
          if (d.status == 200) {
            NotificationManager.success(d.message);
            dispatch(
              getUserOrder(user?.params ? user.params.token : user.token, c, cp)
            );
            dispatch(
              getUserBalance(user?.params ? user.params.token : user.token)
            );
            getCurrentBuySellTokenBalance();
          } else {
            NotificationManager.error(d.message);
          }
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      N_createSellOffer(
        atPrice,
        stopprice,
        amount,
        c,
        cp,
        user?.params ? user.params.token : user.token,
        cprice,
        newController,
        "sell",
        selectedOption
      )
        .then((d) => {
          if (d.status == 200) {
            NotificationManager.success(d.message);
            getCurrentBuySellTokenBalance();
            if (selectedOption == "limit") {
              dispatch(
                getUserBalance(user?.params ? user.params.token : user.token)
              );
              N_executeOrder(
                d.result.order_id,
                user?.params ? user.params.token : user.token,
                d.result.type
              ).then((d) => {
                if (d.status == 200) {
                  getCurrentBuySellTokenBalance();
                  dispatch(
                    getUserBalance(
                      user?.params ? user.params.token : user.token
                    )
                  );
                  dispatch(
                    getUserOrder(
                      user?.params ? user.params.token : user.token,
                      c,
                      cp
                    )
                  );
                }
              });
            }
          } else if (d.status == 400) {
            getCurrentBuySellTokenBalance();
            dispatch(
              getUserBalance(user?.params ? user.params.token : user.token)
            );
            dispatch(
              getUserOrder(user?.params ? user.params.token : user.token, c, cp)
            );
            NotificationManager.success(d.message);
          }
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  return (
    <>
      <div className="card border-0 position-relative">
        {!isLoggedIn ? (
          <div className="buysell_without_login">
            <div className="message_part">
              <h5>Want to get started?</h5>
              <p>Create an account in just a few seconds.</p>
              <div>
                <Link className="btn btn-primary py-2" to="/login">
                  Log In
                </Link>
              </div>
              <p className="mb-0 py-3">OR</p>
              <div>
                <Link className="btn btn-outline-primary" to="/create">
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        ) : null}

        <div className="card-header--  padding_0 buysell_tabs mx-2">
          <div
            className="nav nav-pills nav-justified border-bottom-0 ms-2"
            id="nav-tab"
            role="tablist"
          >
            <div
              className={`nav-item nav-link me-2 buy_tab${
                activeTab == 0 ? " active" : ""
              }`}
              id="nav-home-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-home"
              type="tab"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
              onClick={() => {
                setActiveTab(0);
              }}
            >
              <div className="link-text">Buy</div>
            </div>

            <div
              className={`nav-item nav-link sell_tab${
                activeTab == 1 ? " active" : ""
              }`}
              id="nav-profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-profile"
              type="tab"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
              onClick={() => {
                setActiveTab(1);
              }}
            >
              <div className="link-text">Sell</div>
            </div>
          </div>
        </div>
        {/* <div className="card-header">Stop Limit</div> */}

        <div className="card-body buysell_height" id="open-order">
          <div class="tab-content" id="nav-tabContent">
            <div
              class={`tab-pane${activeTab == 0 ? " active" : ""}`}
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div className="d-flex justify-content-between mb-3">
                <div>
                  {/* Render content based on the selected option */}
                  {selectedOption === "limit" && (
                    <h5 className="fs-13 text-primary"> Limit</h5>
                  )}
                  {selectedOption === "stop" && (
                    <h5 className="fs-13 text-primary">Stop Limit</h5>
                  )}
                  {selectedOption === "market" && (
                    <h5 className="fs-13 text-primary">Market</h5>
                  )}
                </div>
                <div className="fs-13">
                  <select
                    className="custom-select px-2 rounded"
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                  >
                    <option value="limit">Limit</option>
                    <option value="stop">Stop Limit</option>
                    <option value="market">Market</option>
                  </select>
                </div>
              </div>

              <div>
                {selectedOption === "stop" ? (
                  <>
                    <label for="user_email" class="text-lgray">
                      Stop Price
                    </label>
                    <div className="input-group exchange_input_grp mb-3">
                      {/* <span className="input-group-text">Stop Price</span> */}

                      <input
                        type="text"
                        className="form-control buy-sell-form-bg buy-sell-theme"
                        value={stopprice}
                        onChange={(e) => {
                          setStopPrice(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1")
                              .replace(
                                new RegExp(
                                  `^(\\d+\\.\\d{${spreadvalue}}).*`,
                                  "g"
                                ),
                                "$1"
                              )
                          );
                        }}
                      />
                      <span className="input-group-text">INR</span>
                    </div>
                  </>
                ) : null}
                {selectedOption !== "market" ? (
                  <>
                    <label for="user_email" class="text-lgray">
                      Limit Price
                    </label>
                    <div className="input-group mb-3 exchange_input_grp">
                      {/* <span className="input-group-text buy-sell-form-bg buy-sell-theme">
                          Limit Price
                        </span> */}

                      <input
                        type="text"
                        className="form-control buy-sell-form-bg buy-sell-theme"
                        value={atPrice}
                        onChange={(e) => {
                          setATPrice(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1")
                              .replace(
                                new RegExp(
                                  `^(\\d+\\.\\d{${spreadvalue}}).*`,
                                  "g"
                                ),
                                "$1"
                              )
                          );
                          setTotal(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1") * amount
                          );
                        }}
                      />
                      <span className="input-group-text bg-transparent">
                        {coin[1].toUpperCase()}
                      </span>
                    </div>
                  </>
                ) : null}
                <label for="user_email" class="text-lgray">
                  Amount
                </label>
                <div className="input-group mb-3 exchange_input_grp">
                  {/* <span className="input-group-text buy-sell-form-bg buy-sell-theme">
                      Amount
                    </span> */}

                  <input
                    type="text"
                    className="form-control buy-sell-form-bg buy-sell-theme"
                    value={amount}
                    onChange={(e) => {
                      setAmount(
                        e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1")
                      );
                      setTotal(
                        e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1") * atPrice
                      );
                    }}
                  />

                  <span className="input-group-text">
                    {coin[0].toUpperCase()}
                  </span>
                </div>

                <label for="user_email" class="text-lgray">
                  Total
                </label>
                <div className="input-group mb-3 exchange_input_grp">
                  {/* <span className="input-group-text buy-sell-form-bg buy-sell-theme">
                      Total
                    </span> */}

                  <input
                    type="text"
                    className="form-control buy-sell-form-bg buy-sell-theme"
                    value={total}
                    onChange={(e) => {
                      setAmount(
                        e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1") / atPrice
                      );
                      setTotal(
                        e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1")
                      );
                    }}
                  />

                  <span className="input-group-text">
                    {coin[1].toUpperCase()}
                  </span>
                </div>

                <div className="row mt-4 text-muted">
                  <div className="col-12 d-flex justify-content-between">
                    <div
                      className="buy_sell_amount_picker"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => {
                        setTotal(Number(buybalance ? buybalance * 0.25 : 0));
                        setAmount(
                          Number((buybalance ? buybalance * 0.25 : 0) / atPrice)
                        );
                      }}
                    >
                      25%
                    </div>
                    <div
                      className="buy_sell_amount_picker"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => {
                        setTotal(buybalance ? buybalance * 0.5 : 0);
                        setAmount(
                          buybalance ? (buybalance * 0.5) / atPrice : 0
                        );
                      }}
                    >
                      50%
                    </div>
                    <div
                      className="buy_sell_amount_picker"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => {
                        setTotal(buybalance ? buybalance * 0.75 : 0);
                        setAmount(
                          buybalance ? (buybalance * 0.75) / atPrice : 0
                        );
                      }}
                    >
                      75%
                    </div>
                    <div
                      className="buy_sell_amount_picker"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => {
                        setTotal(buybalance ? buybalance : 0);
                        setAmount(buybalance ? buybalance / atPrice : 0);
                      }}
                    >
                      100%
                    </div>
                  </div>
                </div>

                <div className="row mt-4 text-muted">
                  <div className="col-lg-6">
                    {/* <span className="fs-12">
                            Buy {coin[0].toUpperCase()}
                          </span> */}
                    {coin[1] !== campare_currency
                      ? coin[1].toUpperCase()
                      : campare_currency.toUpperCase()}
                  </div>
                  <div className="col-lg-6 text-end fs-12">
                    {/* <span className="mx-2" title="wallet">
                            <i className="fa fa-wallet"> </i>
                          </span> */}
                    {coin[1] !== campare_currency
                      ? buybalance + " " + coin[1].toUpperCase()
                      : buybalance + " "}
                  </div>
                </div>

                <div className="d-grid mt-3">
                  <button
                    className="btn btn-success py-2"
                    disabled={bloading}
                    onClick={() => {
                      if (isLoggedIn) {
                        buyCoin(atPrice, amount, coin[0], coin[1]);
                      } else {
                        NotificationManager.error(
                          "First login then perform buy/sell"
                        );
                      }
                    }}
                  >
                    {bloading ? (
                      <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                    ) : null}
                    BUY {coin[0].toUpperCase()}
                  </button>
                </div>
                <div className="text-muted small text-center mt-1">
                  {`Fee: Maker fee: ${
                    makerFee ? makerFee + "%" : ""
                  }| Taker fee: ${TakerFee ? TakerFee + "%" : ""}`}
                </div>
              </div>

              {/* Stop Limit Hide for now */}
              <div className="d-none">
                <select
                  className="custom-select px-2 rounded buy-sell-form-bg buy-sell-theme"
                  value={stopLimit}
                  onChange={(e) =>
                    setStoplimit(
                      e.target.value
                        .replace(/[^0-9.]/g, "")
                        .replace(/(\..*?)\..*/g, "$1")
                        .replace(
                          new RegExp(`^(\\d+\\.\\d{${spreadvalue}}).*`, "g"),
                          "$1"
                        )
                    )
                  }
                >
                  <option value={0}>Limit</option>
                  <option value={1}>Stop Limit</option>
                </select>
              </div>

              {stopLimit === 1 ? (
                <div className="input-group exchange_input_grp mb-2">
                  <span className="input-group-text">STOP PRICE</span>

                  <input
                    type="text"
                    className="form-control buy-sell-form-bg buy-sell-theme"
                    value="0"
                  />
                  <span className="input-group-text">INR</span>
                </div>
              ) : null}
              {/* Stop Limit Hide for now */}
            </div>

            <div
              class={`tab-pane${activeTab == 1 ? " active" : ""}`}
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              {/* sell tab */}

              <div className="d-flex justify-content-between mb-3">
                <div>
                  {/* Render content based on the selected option */}
                  {selectedOption === "limit" && (
                    <h5 className="fs-13 text-primary"> Limit</h5>
                  )}
                  {selectedOption === "stop" && (
                    <h5 className="fs-13 text-primary">Stop Limit</h5>
                  )}
                  {selectedOption === "market" && (
                    <h5 className="fs-13 text-primary">Market</h5>
                  )}
                </div>
                <div className="fs-13">
                  <select
                    className="custom-select px-2 rounded"
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                  >
                    <option value="limit">Limit</option>
                    <option value="stop">Stop Limit</option>
                    <option value="market">Market</option>
                  </select>
                </div>
              </div>

              <div id="order-history">
                <div className="">
                  {selectedOption === "stop" ? (
                    <>
                      <label for="user_email" class="text-lgray">
                        Stop Price
                      </label>
                      <div className="input-group exchange_input_grp mb-3">
                        {/* <span className="input-group-text">Stop Price</span> */}

                        <input
                          type="text"
                          className="form-control buy-sell-form-bg buy-sell-theme"
                          value={stopprice}
                          onChange={(e) => {
                            setStopPrice(
                              e.target.value
                                .replace(/[^0-9.]/g, "")
                                .replace(/(\..*?)\..*/g, "$1")
                                .replace(
                                  new RegExp(
                                    `^(\\d+\\.\\d{${spreadvalue}}).*`,
                                    "g"
                                  ),
                                  "$1"
                                )
                            );
                          }}
                        />
                        <span className="input-group-text">INR</span>
                      </div>
                    </>
                  ) : null}
                  {selectedOption !== "market" ? (
                    <>
                      <label for="user_email" class="text-lgray">
                        Limit Price
                      </label>

                      <div className="input-group mb-3 exchange_input_grp">
                        <input
                          type="text"
                          className="form-control buy-sell-form-bg buy-sell-theme"
                          value={atsPrice}
                          onChange={(e) => {
                            setATSPrice(
                              e.target.value
                                .replace(/[^0-9.]/g, "")
                                .replace(/(\..*?)\..*/g, "$1")
                                .replace(
                                  new RegExp(
                                    `^(\\d+\\.\\d{${spreadvalue}}).*`,
                                    "g"
                                  ),
                                  "$1"
                                )
                            );
                            setSTotal(
                              e.target.value
                                .replace(/[^0-9.]/g, "")
                                .replace(/(\..*?)\..*/g, "$1") * amount
                            );
                          }}
                        />
                        <div className="input-group-text">
                          {coin[1].toUpperCase()}
                        </div>
                      </div>
                    </>
                  ) : null}

                  <label for="user_email" class="text-lgray">
                    Amount
                  </label>
                  <div className="input-group mb-3 exchange_input_grp">
                    {/* <span className="input-group-text buy-sell-form-bg buy-sell-theme">
                        Amount
                      </span> */}

                    <input
                      type="text"
                      className="form-control buy-sell-form-bg buy-sell-theme"
                      value={asmount}
                      onChange={(e) => {
                        setSAmount(
                          e.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*?)\..*/g, "$1")
                        );
                        setSTotal(
                          e.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*?)\..*/g, "$1") * atsPrice
                        );
                      }}
                    />
                    <span className="input-group-text">
                      {" "}
                      {coin[0].toUpperCase()}
                    </span>
                  </div>
                  <label for="user_email" class="text-lgray">
                    Total
                  </label>
                  <div className="input-group mb-3 exchange_input_grp">
                    {/* <span className="input-group-text buy-sell-form-bg buy-sell-theme">
                        Total
                      </span> */}

                    <input
                      type="text"
                      className="form-control buy-sell-form-bg buy-sell-theme"
                      value={stotal}
                      onChange={(e) => {
                        setSAmount(
                          e.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*?)\..*/g, "$1") / atsPrice
                        );
                        setSTotal(
                          e.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*?)\..*/g, "$1")
                        );
                      }}
                    />
                    <span className="input-group-text">
                      {coin[1].toUpperCase()}
                    </span>
                  </div>

                  <div className="row mt-4 ">
                    <div className="col-12 d-flex justify-content-between">
                      <span
                        className="buy_sell_amount_picker"
                        style={{
                          whiteSpace: "nowrap",
                        }}
                        onClick={() => {
                          setSAmount(sellbalance ? sellbalance * 0.25 : 0);
                          setSTotal(
                            sellbalance ? atsPrice * sellbalance * 0.25 : 0
                          );
                        }}
                      >
                        25%
                      </span>
                      <span
                        className="px-1 buy_sell_amount_picker"
                        style={{
                          whiteSpace: "nowrap",
                        }}
                        onClick={() => {
                          setSAmount(sellbalance ? sellbalance * 0.5 : 0);
                          setSTotal(
                            sellbalance ? atsPrice * sellbalance * 0.5 : 0
                          );
                        }}
                      >
                        50%
                      </span>
                      <span
                        className="pl-1 buy_sell_amount_picker"
                        style={{
                          whiteSpace: "nowrap",
                        }}
                        onClick={() => {
                          setSAmount(sellbalance ? sellbalance * 0.75 : 0);
                          setSTotal(
                            sellbalance ? atsPrice * sellbalance * 0.75 : 0
                          );
                        }}
                      >
                        75%
                      </span>
                      <span
                        className="pl-1 buy_sell_amount_picker"
                        style={{
                          whiteSpace: "nowrap",
                        }}
                        onClick={() => {
                          // setSAmount(sellbalance);
                          // setSTotal(atsPrice * sellbalance);
                          // setSAmount(sellbalance ? sellbalance : 0);
                          // setSTotal(
                          //   sellbalance ? atsPrice * sellbalance : 0
                          // );
                          setSAmount(sellbalance ? sellbalance : 0);
                          setSTotal(sellbalance ? atsPrice * sellbalance : 0);
                        }}
                      >
                        100%
                      </span>
                    </div>
                  </div>
                  <div className="row mt-4 text-muted">
                    <div className="col-lg-6">
                      <span className="fs-12">{coin[0].toUpperCase()}</span>
                    </div>
                    <div className="col-6 text-end fs-12">
                      {/* <span className="me-2" title="wallet">
                            <i className="fa fa-wallet"> </i>
                          </span> */}
                      {sellbalance
                        ? coin[1] !== campare_currency
                          ? sellbalance
                          : sellbalance
                        : 0}{" "}
                    </div>{" "}
                  </div>

                  <div className="d-grid mt-3">
                    <button
                      className="btn btn-danger py-2"
                      disabled={loading}
                      onClick={() => {
                        if (isLoggedIn) {
                          sellCoin(atsPrice, asmount, coin[0], coin[1]);
                        } else {
                          NotificationManager.error(
                            "First login then perform buy/sell"
                          );
                        }
                      }}
                    >
                      {loading ? (
                        <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                      ) : null}
                      SELL {coin[0].toUpperCase()}
                    </button>
                  </div>
                  <div className="text-muted small text-center mt-1">
                    {`Fee: Maker fee: ${
                      makerFee ? makerFee + "%" : ""
                    }| Taker fee: ${TakerFee ? TakerFee + "%" : ""}`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-3">
        <div class="d-flex">
          <div class="value-item sell-price-bg">
            <div class="value-item-label">
              <p class="lt-link-name">SELL</p>
              <p class="text-red">
                <span>{sellorderpercent}</span>
                <span>%</span>
              </p>
            </div>
            <div class="text-lgray">
              {" "}
              <IoIosArrowDropright />
            </div>
          </div>
          <div class="value-item buy-price-bg">
            <div class="value-item-label">
              <p class="lt-link-name">BUY</p>
              <p class="text-green">
                <span>{buyorderpercent}</span>
                <span>%</span>
              </p>
            </div>
            <div class="text-lgray">
              <IoIosArrowDropright />
            </div>
          </div>
        </div>

        {isLoggedIn ? (
          <>
            <h6 className="mb-3 mt-3">Unified Trading Account</h6>

            <p>Spot Trade</p>

            <div class="d-flex justify-content-between mt-3">
              <div>
                <HtmlTooltip
                  title="The amount of this asset you hold in your Unified Trading Account."
                  arrow
                >
                  <div className="tooltip_text mb-2">
                    {" "}
                    {coin[0].toUpperCase()} Balance
                  </div>
                </HtmlTooltip>
              </div>
              <div>
                {sellbalance} {coin[0].toUpperCase()}{" "}
              </div>
            </div>
            <div class="d-flex justify-content-between ">
              <div>
                <HtmlTooltip
                  title="The amount of this asset you hold in your Unified Trading Account."
                  arrow
                >
                  <div className="tooltip_text">
                    {coin[1].toUpperCase()} Balance
                  </div>
                </HtmlTooltip>
              </div>
              <div>
                {buybalance} {coin[1].toUpperCase()}{" "}
              </div>
            </div>

            <div className="d-flex gap-2">
              <Link to="/AssetsDeposit" className="trade_btn">
                Deposit
              </Link>
              <Link to="/AssetsWithdraw" className="trade_btn">
                Withdraw
              </Link>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}
